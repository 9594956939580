import React, { useState } from 'react'
import { MdExitToApp, MdPerson } from 'react-icons/md'
import { logout, useLogin } from '../utils/AppFunctions'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../utils/AppHelpers'

export default function AdminHeader({ title, subtitle }) {
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)
    const isLogin = useLogin("staff")
    // if (!isLogin) {
    //     navigate("/")
    // }

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    return (
        <div style={{ width: "100%", backgroundColor: "black", position: "sticky", top: 0, padding: isMobile(width) ? "20px 10px" : "30px 20px", alignItems: "center", zIndex: 20 }} className='row justifySplit alignCenter' id='admin-header'>

            {isMobile(width) ? <img src='/logo.png' style={{ height: 50, width: 80, objectFit: "contain", cursor: "pointer" }} alt="" onClick={() => {
                // send out a event to show the sidebar
                const event = new CustomEvent("show-sidebar")
                window.dispatchEvent(event)
            }} /> :
                <div>
                    <p style={{ fontSize: 20 }}>{title}</p>
                    {subtitle && <p style={{ fontWeight: "bold" }}>{subtitle}</p>}
                </div>
            }
            <div className='row alignCenter gap-20'>
                <div style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }}
                    onClick={() => {
                        window.location.href = "/user/home"
                    }}
                >
                    <MdPerson size={20} color="white" />
                    <p style={{ color: "white" }}>User</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }}
                    onClick={() => {
                        logout()
                    }}
                >
                    <MdExitToApp size={20} color="white" />
                    <p style={{ color: "white" }}>LOGOUT</p>
                </div>
            </div>
        </div>
    )
}
