import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { isMobile, tidyDate } from '../../utils/AppHelpers';
import { useFetchUser } from '../../utils/AppFunctions';

export default function AdminUserDetailPage() {
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)
    const [isLoaded, setIsLoaded] = useState(false)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    const { state } = useLocation()
    const currentUser = useFetchUser()
    const { user_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [points, setPoints] = useState(0)
    const [optionType, setOptionType] = useState("add")
    const [userDetails, setUserDetails] = useState({
        email: "",
        password: "",
        telegram: "",
        username: "",
        account_type: "user",
        remarks: "",
    })

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetchUserById = async () => {
        if (user_id === "new") return
        let endpoint = config.endpoint + `/users/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setUserDetails(data.data)
        }
    }

    const submitNewUser = async () => {
        if (user_id !== "new") return toast.error("Cannot create a new user, please try again later")
        if (!userDetails?.username) return toast.error("Please enter a username")
        if (!userDetails?.password) return toast.error("Please enter a password")
        // if (!userDetails?.email) return toast.error("Please enter an email")
        let endpoint = config.endpoint + `/users`
        let response = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...userDetails,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            window.location.href = "/admin/users/id/" + data.data
            toast.success("New User created successfully")
        } else {
            toast.error("Failed to create user")
        }
    }

    const updateUser = async () => {
        if (user_id === "new") return toast.error("Cannot updated user at this time, please try again later")
        let endpoint = config.endpoint + `/users/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...userDetails,
                id: user_id,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("User updated successfully")
        } else {
            toast.error("Failed to update user")
        }
    }

    const addPointsToUser = async () => {
        if (user_id === "new") return toast.error("Cannot updated user at this time, please try again later")
        let endpoint = config.endpoint + `/game/points/add/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id,
                points
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Added points to user successfully")
        } else {
            toast.error("Failed to add points to user")
        }
    }

    const subtractPointsFromUser = async () => {
        if (user_id === "new") return toast.error("Cannot updated user at this time, please try again later")
        let endpoint = config.endpoint + `/game/points/subtract/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id,
                points
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Subtracted points from user successfully")
        } else {
            toast.error("Failed to subtract points from user")
        }
    }

    const deleteUser = async () => {
        if (user_id === "new") return toast.error("Unable to delete user at this time, please try again later")
        let endpoint = config.endpoint + `/users/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("User deleted successfully")
            navigate(-1)
        } else {
            toast.error("Failed to delete user")
        }
    }

    const unlockAccount = async () => {
        let endpoint = config.endpoint + `/users/unlock/id/${user_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Account unlocked successfully")
            fetchUserById()
        } else {
            toast.error("Failed to unlock account")
        }
    }

    useEffect(() => {
        fetchUserById()
    }, [])


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Admin & Users"}/>
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top, display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <div>
                        <p className="component-title">{user_id === "new" ? "New User" : "User Details"}</p>
                        <p className="component-subtitle">{user_id === "new" ? "Create a new user" : "View and edit user details"}</p>
                    </div>
                </div>
                <div className="inner-container" style={{ height: "100%" }}>
                    <div className="row gap-10 alignTop" style={{ height: "100%" }}>
                        <div style={{ width: "100%", height: "100%" }} className='gap-10 flex column'>
                            <div className="card">
                                <p className="card-title">Details</p>
                                <div className="row gap-10">
                                    <div className="input-container">
                                        <p className="input-label">Username</p>
                                        <input className='text-input' placeholder='username' value={userDetails?.username} onChange={(e) => {
                                            setUserDetails({ ...userDetails, username: e.target.value })
                                        }} />
                                    </div>

                                    <div className="input-container">
                                        <p className="input-label">Role</p>
                                        <select className='select-input' value={userDetails?.account_type} onChange={(e) => {
                                            setUserDetails({ ...userDetails, account_type: e.target.value })
                                        }}>
                                            <option value="admin">Super Admin</option>
                                            <option value="staff">Admin</option>
                                            <option value="user">User</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row gap-10">
                                    <div className="input-container">
                                        <p className="input-label">Telegram</p>
                                        <input className='text-input' placeholder='@telegram' value={userDetails?.telegram} onChange={(e) => {
                                            setUserDetails({ ...userDetails, telegram: e.target.value })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Email</p>
                                        <input className='text-input' placeholder='email@sit.com' value={userDetails?.email} onChange={(e) => {
                                            setUserDetails({ ...userDetails, email: e.target.value })
                                        }} />
                                    </div>
                                </div>
                            </div>
                            {user_id !== "new" && <div className="card">
                                <p className="card-title">Points Distribution</p>
                                <div className="row gap-10">
                                    <div className="input-container">
                                        <p className="input-label">Option</p>
                                        <select className='select-input' value={optionType} onChange={(e) => {
                                            setOptionType(e.target.value)
                                        }}>
                                            <option value="add">Add</option>
                                            <option value="subtract">Subtract</option>
                                        </select>
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Points</p>
                                        <input className='text-input' placeholder='points' value={points} onChange={(e) => {
                                            setPoints(e.target.value)
                                        }} />
                                    </div>
                                    <div className="button-container">
                                        <button className="primary-button" onClick={() => {
                                            if (optionType === "add") {
                                                addPointsToUser()
                                            } else {
                                                subtractPointsFromUser()
                                            }
                                        }}>
                                            {optionType === "add" ? "Add" : "Subtract"}
                                        </button>
                                    </div>
                                </div>
                            </div>}
                            <div className="card">
                                <p className="card-title">Password</p>
                                {user_id !== "new" && <p className='subheader-text mb-20'>Leave blank to keep existing password, or enter a new password to update</p>}
                                <div className="input-container">
                                    <p className="input-label">Password</p>
                                    <input className='text-input' placeholder='password' value={userDetails?.password} onChange={(e) => {
                                        setUserDetails({ ...userDetails, password: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div className="card">
                                {user_id !== "new" && <div className="input-container ">
                                    <p className="card-title">Internal Action</p>
                                    {currentUser?.account_type === "admin" && <div className="row gap-10" style={{ flexWrap: isMobile(width) && "wrap" }}>
                                        <div className="input-container">
                                            <p className="input-label">Created At</p>
                                            <input className='text-input' placeholder='created at' value={tidyDate(userDetails?.createdAt)} disabled />
                                        </div>
                                        <div className="input-container">
                                            <p className="input-label">Last Log In</p>
                                            <input className='text-input' placeholder='Have not logged in yet' value={tidyDate(userDetails?.last_login)} disabled />
                                        </div>
                                        <div className="input-container">
                                            <p className="input-label">Account Locked Till</p>
                                            <input className='text-input' placeholder='Account not locked' value={tidyDate(userDetails?.account_locked_till)} disabled />
                                            {userDetails?.account_locked_till && <div>
                                                <button className="backgroundLess mt-10" style={{ fontSize: 12 }} onClick={() => {
                                                    unlockAccount()
                                                }}>
                                                    Unlock Account
                                                </button>
                                            </div>}
                                        </div>
                                    </div>}
                                    <textarea className='textarea-input mt-10' placeholder='remarks' value={userDetails?.remarks} onChange={(e) => {
                                        setUserDetails({ ...userDetails, remarks: e.target.value })
                                    }} />
                                </div>}
                                {!isMobile(width) && <div className="button-container justifyRight gap-10">
                                    {user_id !== "new" &&
                                        <button className="delete-button" onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this user?")) deleteUser()
                                        }}>
                                            Delete
                                        </button>
                                    }
                                    <button className="primary-button" onClick={() => {
                                        if (user_id === "new") {
                                            submitNewUser()
                                        } else {
                                            updateUser()
                                        }
                                    }}>
                                        Save
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile(width) && <div
                    style={{ backgroundColor: "white", padding: 20, position: "sticky", bottom: 0, zIndex: 50, boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
                >
                    <div className="column gap-10" style={{ gap: 10 }}>

                        {user_id !== "new" &&
                            <button className="delete-button" style={{ width: "100%" }} onClick={() => {
                                if (window.confirm("Are you sure you want to delete this user?")) deleteUser()
                            }}>
                                Delete
                            </button>
                        }
                        <button className="primary-button" style={{ width: "100%" }} onClick={() => {
                            if (user_id === "new") {
                                submitNewUser()
                            } else {
                                updateUser()
                            }
                        }}>
                            Save
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    )
}
