import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Header() {
    let navigate = useNavigate()
    return (
        <div className="row justifySplit p-all" style={{ backgroundColor: "#222" }}>
            <img src="../logo.png" alt="logo" style={{ height: 70, width: 70, objectFit: "contain" }} />
            <button className="backgroundLess" style={{ color: "white" }}
                onClick={() => navigate("/auth/login")}
            >Login</button>
        </div>
    )
}
