import React, { useState, useEffect } from 'react'
import UserHeader from '../../components/UserHeader'
import { useFetchUser } from '../../utils/AppFunctions'
import config from '../../app-config'
import { tidyDate } from '../../utils/AppHelpers'
import { MdConnectingAirports, MdHouse, MdOutlinePersonPinCircle, MdPerson, MdPersonPinCircle, MdSettings } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import notify from '../../utils/IANotification'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function UserProfilePage() {
    const user = useFetchUser()
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [pageState, setPageState] = useState("profile") // profile, password
    const [oldPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")

    const fetchUserProfile = async () => {
        if (!user?.id) return
        let endpoint = config.endpoint + "/auth/profile"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setUserData(data.data)
        }
    }

    const datetime = () => {
        let dateObj = new Date()
        let hours = dateObj.getHours()
        let minutes = dateObj.getMinutes()
        let seconds = dateObj.getSeconds()
        let day = dateObj.getDay()
        let date = dateObj.getDate()
        let month = dateObj.getMonth()
        let year = dateObj.getFullYear()
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
    }


    const changePassword = async () => {
        let endpoint = config.endpoint + "/auth/profile/password"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                oldPassword,
                password,
                passwordConfirm
            })
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setOldPassword("")
            setPassword("")
            setPasswordConfirm("")
            setPageState("profile")
            notify({ type: "success", message: "Password changed successfully" })
        } else {
            notify({ type: "error", message: "Password change failed" })
        }
    }

    const saveProfile = async () => {
        let endpoint = config.endpoint + "/auth/profile"
        let fetchResponse = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userData)
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            notify({ type: "success", message: "Profile saved successfully" })
        }else{
            notify({ type: "error", message: "Profile save failed" })
        }
    }

    useEffect(() => {
        fetchUserProfile()
    }, [user])

    return (
        <div style={{ height: "100dvh" }}>
            <UserHeader />
            <ToastContainer/>
            <div className='container' style={{ height: "auto" }}>
                <div className="inner-container">
                    <div className="row alignCenter gap-10 cursor-pointer"
                        onClick={() => {
                            navigate("/user/home")
                        }}
                    >
                        <MdHouse size={30} color='black' />
                        <h2 style={{ color: "black" }}>Home</h2>
                    </div>
                    <div className="card mt-10" style={{ borderRadius: 10 }}>
                        <p className="card-title">Profile Settings</p>
                        {pageState === "profile" &&
                            <>
                                <div className="input-container">
                                    <p className="input-label">Username*</p>
                                    <input className="text-input" value={userData?.username} onChange={(e) => setUserData({ ...userData, username: e.target.value })} />
                                </div>
                                <div className="input-container">
                                    <p className="input-label">Email <span style={{fontSize:10}}>(optional)</span></p>
                                    <input className="text-input" value={userData?.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                                </div>
                                <div className="input-container">
                                    <p className="input-label">Telegram Handle <span style={{fontSize:10}}>(optional)</span></p>
                                    <input className="text-input" value={userData?.telegram} onChange={(e) => setUserData({ ...userData, telegram: e.target.value })} />
                                </div>
                                <div className="center alignCenter">
                                    <button className="primary-button" style={{ backgroundColor: "black", width: "100%" }} onClick={() => {
                                        saveProfile()
                                    }}>Save</button>
                                    <button className="backgroundLess mt-10" style={{ width: "100%" }} onClick={() => {
                                        setPageState("password")
                                    }}>Change Password</button>
                                </div>
                            </>
                        }

                        {pageState === "password" &&
                            <>
                                <div className="input-container">
                                    <p className="input-label">Old Password</p>
                                    <input type='password' className="text-input" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                </div>
                                <div className="input-container">
                                    <p className="input-label">New Password</p>
                                    <input type='password' className="text-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="input-container">
                                    <p className="input-label">Confirm Password</p>
                                    <input type='password' className="text-input" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                                </div>
                                <div className="center alignCenter">
                                    <button className="primary-button" style={{ backgroundColor: "black", width: "100%" }} onClick={() => {
                                        changePassword()
                                    }}>Change</button>
                                    <button className="backgroundLess mt-10" style={{ width: "100%" }} onClick={() => {
                                        setPageState("profile")
                                    }}>Back</button>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
