import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAuthorisation, logout } from '../utils/AppFunctions'
import { isMobile } from '../utils/AppHelpers'

export default function AdminNavbar({ view }) {

    const [selectedNav, setSelectedNav] = useState(view ? view : "")
    let navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)
    const [showSidebar, setShowSidebar] = useState(isMobile(width) ? false : true)

    let data = [
        {
            name: "Dashboard",
            icon: "",
            link: "/admin/dashboard",
            children: [
                {
                    name: "Overview",
                    icon: "",
                    link: "/admin"
                }
            ]
        },
        // {
        //     name: "Inspections",
        //     icon: "",
        //     link: "/admin/inspections",
        //     children: [
        //         {
        //             name: "Inspections",
        //             icon: "",
        //             link: "/admin/inspections"
        //         },

        //     ]
        // },
        {
            name: "Reports",
            icon: "",
            link: "/admin/reports",
            children: [
                {
                    name: "All Reports",
                    icon: "",
                    link: "/admin/reports?filter=all"
                },
                {
                    name: "Incident",
                    icon: "",
                    link: "/admin/reports?filter=incident"
                },
                {
                    name: "Suggestions",
                    icon: "",
                    link: "/admin/reports?filter=suggestion"
                },
                {
                    name: "Compliments",
                    icon: "",
                    link: "/admin/reports?filter=compliment"
                },
                {
                    name: "First-Aid",
                    icon: "",
                    link: "/admin/reports?filter=firstaid"
                },
                {
                    name: "Inspections",
                    icon: "",
                    link: "/admin/reports?filter=inspection"
                },
            ]
        },
        {
            name: "Gamification",
            icon: "",
            link: "/admin/gamification",
            children: [
                {
                    name: "Leaderboard",
                    icon: "",
                    link: "/admin/gamification/leaderboard",
                },
                // {
                //     name: "Rewards",
                //     icon: "",
                //     link: "/admin/gamification/rewards",
                // },
                {
                    name: "Settings",
                    icon: "",
                    link: "/admin/gamification/settings",
                },
            ]
        },
        {
            name: "Admin & Users",
            icon: "",
            link: "/admin/user",
            children: [
                {
                    name: "SIT Admin",
                    icon: "",
                    link: "/admin/users?filter=staff",
                },
                {
                    name: "Users",
                    icon: "",
                    link: "/admin/users",
                },
                {
                    name: "Manage",
                    icon: "",
                    link: "/admin/users/manage",
                }
            ]
        },
        {
            name: "Locations",
            icon: "",
            link: "/admin/locations",
            children: [
                {
                    name: "Locations",
                    icon: "",
                    link: "/admin/tools/locations"
                },
                {
                    name: "SIT Campus",
                    icon: "",
                    link: "/admin/tools/campus"
                },
            ]
        },
        {
            name: "Tools",
            icon: "",
            link: "/admin/tools",
            children: [
                {
                    name: "Control Panel",
                    icon: "",
                    link: "/admin/tools/control-panel",
                },
                {
                    name: "Platform Reports",
                    icon: "",
                    link: "/admin/tools/reports"
                },
                {
                    name: "Checklist",
                    icon: "",
                    link: "/admin/checklist"
                },
            ]
        },
    ]

    // useEffect(() => {
    //     fetchAuthorisation("staff")
    //         .then((res) => {
    //             if (res.auth) {
    //                 console.log("Authorised.")
    //             } else {
    //                 console.log("Not authorised.")
    //                 navigate("/login")
    //             }
    //         })
    // }, [])
    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })
    window.addEventListener("show-sidebar", () => {
        if (isMobile(window.innerWidth)) {
            setShowSidebar(!showSidebar)
        }
    })

    if (showSidebar) {

        return (
            <div className='row'>
                <div className='column justifySplit p-all' style={{ height: "100vh", backgroundColor: "black", width: isMobile(width) ? "200px" : "300px", position: isMobile(width) ? "fixed" : "sticky", zIndex: 100 }}>
                    <div style={{ width: "100%" }}>
                        <img src='/logo.png' style={{ height: 100, width: 150, objectFit: "contain", marginBottom: 50, marginTop: 20 }} alt="" />
                        <div style={{ width: "100%" }}>
                            {data.map((item, index) => {
                                return (
                                    <div className='mt-20 mb-30'>
                                        <p style={{ fontSize: 14, fontWeight: "bold", color: "gray", cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                if (selectedNav == item.name) { setSelectedNav(""); return }
                                                setSelectedNav(item.name)
                                            }}
                                        >{item.name.toUpperCase()}</p>
                                        {selectedNav === item.name && <div>
                                            {item.children && item.children.map((child, index) => {
                                                return (
                                                    <p style={{ color: "white", cursor: "pointer" }} className='mt-10'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            window.location.href = child.link
                                                        }}
                                                    >{child.name}</p>
                                                )
                                            })}
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <p className='textLeft cursor-pointer' style={{ color: "white" }}
                            onClick={(e) => {
                                logout()
                            }}
                        >LOGOUT</p>
                    </div>
                </div>
                {isMobile(width) && <div
                    onClick={() => {
                        setShowSidebar(false)
                    }}
                    style={{ position: "fixed", top: 0, left: 0, height: "100vh", width: "100vw", zIndex: 99 }}
                >
                </div>}

            </div>
        )
    } else {
        return <div></div>
    }
}
