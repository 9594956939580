import React, { useState, useEffect, useRef, useCallback } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { isMobile } from '../../utils/AppHelpers';
import { FaBuilding, FaPlus } from 'react-icons/fa';

export default function AdminCampusPage() {
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    const { state } = useLocation()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [responseData, setResponseData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const observerRef = useRef(null);
    const lastId = responseData.length ? responseData[responseData.length - 1].id : null;
    const [loadingMessage, setLoadingMessage] = useState("")

    const fetchCampus = useCallback(async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);
        let endpoint = config.endpoint + `/campus?last_id=${lastId || ""}`

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            let data = await response.json()

            if (data.status === 200) {
                if (data.data.length === 0) {
                    setLoadingMessage(data.message)
                    setHasMore(false)
                } else {
                    setResponseData((prevData) => [...prevData, ...data.data]);
                }
            }
        } catch (error) {
            console.error("API Error:", error);
            setLoadingMessage("Failed to load data.")
        }

        setTimeout(() => setIsLoading(false), 1000);
    }, [lastId, isLoading, hasMore])

    useEffect(() => {
        if (!responseData.length && !hasMore) return;

        const observer = new IntersectionObserver(
            ([list]) => {
                if (list.isIntersecting && !isLoading) fetchCampus();

            },
            { rootMargin: "200px", threshold: 0.5 }
        );

        const currentObserverRef = observerRef.current;
        if (currentObserverRef) observer.observe(currentObserverRef);

        return () => observer.disconnect();
    }, [responseData, hasMore, isLoading, fetchCampus]);

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Locations"} />
            <div className='container'>
                <AdminHeader />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">SIT</p>
                        <p className="component-subtitle">Campus</p>
                    </div>
                    {/* <button className='secondary-button'
                        onClick={() => {
                            navigate('/admin/tools/campus/create')
                        }}
                    >Add Campus</button> */}

                    <button className='backgroundLess row gap-10'
                        onClick={() => {
                            navigate('/admin/tools/campus/create')
                        }}

                    >
                        <FaPlus style={{}} />
                        Add Campus</button>
                </div>
                <div className="inner-container">
                    {responseData.length > 0 ? (
                        responseData.map((campus, index) => {
                            return (
                                <div key={index} className="card cursor-pointer mb-10"
                                    onClick={() => {
                                        navigate(`/admin/tools/campus/${campus?.id}`, { state: campus })
                                    }}
                                >
                                    <p className="card-title" style={{ padding: 0, margin: 0 }}>{campus?.campus_name}</p>
                                    <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{campus?.campus_code}</p>
                                </div>
                            )
                        })) : (
                        <p className="component-title text-center">{loadingMessage}</p>
                    )}


                    <div ref={observerRef}>
                        {isLoading && responseData.length > 0 && <p className="component-title text-center">Loading data...</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}
