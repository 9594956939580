import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { calculate_time_since, tidyDate } from '../../utils/AppHelpers';

export default function AdminUserManagePage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [selectedUsers, setSelectedUsers] = useState([])
    // query string
    // const [filter, setFilter] = useState(new URLSearchParams(window.location.search).get('filter') || "all")
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [userData, setUserData] = useState([])
    const [search, setSearch] = useState("")

    const [activeData, setActiveData] = useState("acc_created") // acc_created, last_login
    const [duration, setDuration] = useState(1) // days

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetch_all_users = async () => {
        let endpoint = config.endpoint + "/users/"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            setUserData(data.data)
        }
    }

    const delete_users = async () => {
        let endpoint = config.endpoint + "/users/bulk"
        let response = await fetch(endpoint, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                users: selectedUsers
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            notify({ type: "success", message: "Successfully deleted users" })
            fetch_all_users()
            setSelectedUsers([])
        } else {
            notify({ type: "error", message: "Failed to delete users" })
        }
    }

    useEffect(() => {
        fetch_all_users()
    }, [])

    useEffect(() => {
        let now = new Date()
        let newUserData = [...userData]
        let filter = []

        newUserData.map((user, index) => {
            if (activeData === "acc_created") {
                let createdAt = new Date(user?.createdAt)
                let timeDiff = now.getTime() - createdAt.getTime()
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
                if (diffDays > duration) {
                    filter.push(user?.id)
                }
            } else if (activeData === "last_login") {
                let last_login = new Date(user?.last_login)
                let timeDiff = now.getTime() - last_login.getTime()
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
                if (diffDays > duration) {
                    filter.push(user?.id)
                }
            }
        })
        setSelectedUsers(filter)
    } , [activeData, duration])


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Admin & Users"}/>
            <div className='container'>
                <AdminHeader title={"Overview"} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Users</p>
                        <p className="component-subtitle">Manage users</p>
                    </div>
                    <button className='secondary-button delete-button'
                        disabled={selectedUsers?.length === 0}
                        onClick={() => {
                            delete_users()
                        }}
                    >Delete {selectedUsers?.length} users</button>
                </div>
                <div className="inner-container">
                    <div className="row gap-10 alignCenter mb-20">
                        <input
                            className="text-input"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                        {/* <select
                            className="select-input"
                            value={filter}
                            style={{ width: 200 }}
                            onChange={(e) => {
                                setFilter(e.target.value)
                            }}
                        >
                            <option value="all">All</option>
                            <option value="user">Users</option>
                            <option value="staff">Staff</option>
                        </select> */}
                    </div>

                    <div style={{ padding: 10, marginBottom: 30 }} className='card'>
                        <p className='card-title'>Bulk Selection</p>
                        <div className="row gap-10 alignCenter">
                            <div className="input-container">
                                <p className="input-label">Active Data</p>
                                <select
                                    className="select-input"
                                    value={activeData}
                                    // style={{ width: 200 }}
                                    onChange={(e) => {
                                        setActiveData(e.target.value)
                                    }}
                                >
                                    <option value="acc_created">Account Created</option>
                                    <option value="last_login">Last Login</option>
                                </select>
                            </div>
                            <div className="input-container">
                                <p className="input-label">Duration</p>
                                <select className="select-input"
                                    value={duration}
                                    // style={{ width: 200 }}
                                    onChange={(e) => {
                                        setDuration(e.target.value)
                                    }}
                                >
                                    <option value={1}>1 day</option>
                                    <option value={7}>1 week</option>
                                    <option value={30}>1 month</option>
                                    <option value={90}>3 months</option>
                                    <option value={180}>6 months</option>
                                    <option value={365}>1 year</option>
                                </select>
                            </div>
                        </div>
                        <p style={{fontSize:15, color:"gray"}}>Selecting users {activeData === "acc_created" ? "Account created" : "Last logged in"} more than {duration} days ago</p>
                    </div>
                    {userData?.map((user, index) => {
                        let color = "gray"
                        let account_name = user?.account_type
                        if (user?.account_type === "user") {
                            color = "green"
                            account_name = "User"
                        }
                        if (user?.account_type === "staff") {
                            color = "orange"
                            account_name = "Admin"
                        }
                        if (user?.account_type === "admin") {
                            color = "red"
                            account_name = "Super Admin"
                        }
                        {/* if (filter === "user" && user?.account_type !== "user") return
                        if (filter === "staff" && (user?.account_type !== "staff" && user?.account_type !== "admin")) return */}
                        if (search !== "" && !JSON.stringify(user).toLowerCase().includes(search.toLowerCase())) return
                        return (
                            <div className="card mb-10 cursor-pointer"
                                style={{
                                    border: selectedUsers?.includes(user?.id) ? "2px solid #00A4EF" : "2px solid transparent",
                                }}
                                onClick={() => {
                                    let newSelectedUsers = [...selectedUsers]
                                    if (newSelectedUsers?.includes(user?.id)) {
                                        newSelectedUsers = newSelectedUsers.filter((item) => item !== user?.id)
                                    } else {
                                        newSelectedUsers.push(user?.id)
                                    }
                                    setSelectedUsers(newSelectedUsers)
                                }}
                            >
                                <div className="row alignCenter justifySplit" style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        <p className="card-title" style={{ margin: 0, padding: 0 }}>{user?.email || user?.username || user?.telegram}</p>
                                        <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{calculate_time_since(user?.createdAt)}, last logged in {calculate_time_since(user?.last_login)}</p>
                                    </div>
                                    <div className="row gap-10 alignCenter">
                                        <p style={{ backgroundColor: color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold", borderRadius: 20 }}>{account_name}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}
