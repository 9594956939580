import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../app-config'
import QRCode from "qrcode.react";
import { isMobile } from '../../utils/AppHelpers';
import { useFetchAuthorisation } from '../../utils/AppFunctions';

export default function AdminLocationDetailPage() {
    const navigate = useNavigate()
    // const { state } = useLocation()
    const { location_id } = useParams()
    const [width, setWidth] = useState(window.innerWidth)

    const isAuthorised = useFetchAuthorisation("admin")

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    let modal = {
        location_name: '',
        location_code: '',
        is_active: '',
        location_remarks: '',
        campus_id: '',
        location_latitude: '',
        location_longitude: '',
        location_range: '',
        location_address: '',
        inspections_checklist_id: '',
        first_aid_checklist_id: '',
    }

    const [locationDetails, setLocationDetails] = useState(modal)
    const [campusData, setCampusData] = useState([])
    const [checklistData, setChecklistData] = useState([])

    const fetchCampus = async () => {
        let endpoint = config.endpoint + `/campus/`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCampusData(data.data)
        }
    }

    const fetchLocationById = async () => {
        if (location_id === "create") return
        let endpoint = config.endpoint + `/location/id/${location_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setLocationDetails(data.data)
        }
    }

    const fetchChecklists = async () => {
        let endpoint = config.endpoint + `/checklist/`

        const response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let data = await response.json()

        if (data.status === 200) {
            setChecklistData(data.data)
        }
    }


    const saveLocation = async () => {
        let endpoint = config.endpoint + `/location/`
        let response = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...locationDetails
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Location saved successfully!")
            navigate(`/admin/tools/locations`)
        } else {
            toast.error(`Failed to save location!\n${data.message}`)
        }
    }

    const updateLocation = async () => {
        let endpoint = config.endpoint + `/location/${location_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...locationDetails
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Location updated successfully!")
            navigate(`/admin/tools/locations`)
        } else {
            toast.error(`Failed to update location!\n${data.message}`)
        }
    }

    const deleteLocation = async () => {
        let endpoint = config.endpoint + `/location/${location_id}`
        let response = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Location deleted successfully!")
            navigate(`/admin/tools/locations`)
        } else {
            toast.error(`Failed to delete location!\n${data.message}`)
        }
    }


    const refreshQrCode = async () => {
        let endpoint = config.endpoint + `/location/qr/${location_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("QR Code refreshed successfully!")
            fetchLocationById()
            setLocationDetails({
                ...locationDetails,
                qr_code_id: data.data.qr_code_id
            })
        } else {
            toast.error(`Failed to refresh QR Code!\n${data.message}`)
        }
    }

    const downloadQR = () => {
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${locationDetails?.location_code}.png`
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        fetchCampus()
        fetchChecklists()
        fetchLocationById()
    }, [])

/*
    useEffect(() => {
        if (location_id === "create") {
            setLocationDetails({
                ...locationDetails,
                location_code: `${campusData?.filter((campus) => campus.id == locationDetails?.campus_id)[0]?.campus_code?.toUpperCase()}_${locationDetails?.location_name?.replace(/\s/g, '-')?.toUpperCase()}`
            })
        }
    }, [locationDetails])
*/

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Locations"} />
            <div className='container'>
                <AdminHeader />
                <div className="component" style={{ position: "sticky", top: document?.getElementById("admin-header")?.clientHeight }}>
                    <div>
                        <p className="component-title">Location</p>
                        <p className="component-subtitle">{location_id === "create" ? "Create" : "Edit"} Campus Locations</p>
                    </div>
                    {!isMobile(width) && <div className="row gap-10" style={{ gap: 10 }}>

                        {location_id !== "create" &&
                            <button className="delete-button"
                                onClick={() => {
                                    deleteLocation()
                                }}>Delete</button>
                        }

                        <button className="primary-button"
                            onClick={() => {
                                if (location_id === "create") {
                                    saveLocation()
                                } else {
                                    updateLocation()
                                }
                            }}
                        >Save</button>
                    </div>}
                </div>
                <div className="inner-container">
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10`}>
                        <div className={`${((location_id === "create" && isMobile(width))) ? isMobile(width)? "column" : "row" : "column"} gap-10`} style={{ width: "100%" }}>
                        {/* 
                            if location_id === "create" and isMobile(width)
                            then if isMobile(width) is true, then the following div will be column
                            else the following div will be row
                         */}
                            <div className="card">
                                <p className="card-title">Location Details</p>
                                <div className="row gap-10">
                                    <div className="input-container">
                                        <p className="input-label">Name</p>
                                        <input className="text-input" type="text" placeholder="Location Name" value={locationDetails?.location_name} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                location_name: e.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Location Code</p>
                                        <input className="text-input" type="text" placeholder="Location Code" value={locationDetails?.location_code} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                location_code: e.target.value.replaceAll(" ","")
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Campus</p>
                                        <select className="select-input" value={locationDetails?.campus_id} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                campus_id: e.target.value
                                            })
                                        }}>
                                            <option value="">Select Campus</option>
                                            {campusData.map((campus, index) => {
                                                return (
                                                    <option key={index} value={campus.id}>{campus.campus_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <p className="input-label">Location Description</p>
                                    <textarea className="textarea-input" value={locationDetails?.location_remarks} placeholder='Descriptions' onChange={(e) => {
                                        setLocationDetails({
                                            ...locationDetails,
                                            location_remarks: e.target.value
                                        })
                                    }} />
                                </div>
                            </div>
                            <div className="card">
                                <p className="card-title">Coordinates</p>
                                <div className="row gap-10 alignCenter" style={{ flexWrap: isMobile(width) && "wrap" }}>
                                    <div className="input-container">
                                        <p className="input-label">Latitude</p>
                                        <input className="text-input" type="text" placeholder="Latitude" value={locationDetails?.location_latitude} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                location_latitude: e.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Longitude</p>
                                        <input className="text-input" type="text" placeholder="Longitude" value={locationDetails?.location_longitude} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                location_longitude: e.target.value
                                            })
                                        }} />
                                    </div>
                                    <div className="input-container">
                                        <p className="input-label">Range</p>
                                        <input className="text-input" type="text" placeholder="Range" value={locationDetails?.location_range} onChange={(e) => {
                                            setLocationDetails({
                                                ...locationDetails,
                                                location_range: e.target.value
                                            })
                                        }} />
                                    </div>


                                    <button className="primary-button"
                                        style={{ fontSize: 12 }}
                                        onClick={() => {
                                            navigator?.geolocation?.getCurrentPosition((position) => {
                                                setLocationDetails({
                                                    ...locationDetails,
                                                    location_latitude: position.coords.latitude,
                                                    location_longitude: position.coords.longitude,
                                                })
                                            })
                                        }}
                                    >Use Current Location</button>
                                </div>
                                <div className="input-container" style={{ marginTop: isMobile(width) && 20 }}>
                                    <p className="input-label">Address</p>
                                    <textarea className="textarea-input" value={locationDetails?.location_address} placeholder='Address' onChange={(e) => {
                                        setLocationDetails({
                                            ...locationDetails,
                                            location_address: e.target.value
                                        })
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: isMobile(width) ? "100%" : "60%", height: "100%" }}>
                            {location_id !== "create" &&
                                <div className="card mb-10" >
                                    <p className="card-title">Location QR Code</p>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <QRCode value={`${config.local_url}/go?location=${locationDetails?.qr_code_id}&name=${locationDetails?.location_code}`} id='qr-code' size={200} />
                                    </div>
                                    <div className="row center pt-30 alignCenter " style={{ gap: 10, flexWrap: "wrap" }}>
                                        <button className="primary-button"
                                            onClick={() => {
                                                downloadQR()
                                            }}
                                        >Download</button>
                                        <button className="primary-button"
                                            onClick={() => {
                                                window.open(`${config.local_url}/go?location=${locationDetails?.qr_code_id}&name=${locationDetails?.location_code}`, "_blank")
                                                toast.success("Copied to clipboard!")
                                            }}
                                        >View</button>
                                        {isAuthorised && <button className="primary-button"
                                            onClick={() => {
                                                if (!window.confirm("Are you sure you want to refresh the QR Code?")) return
                                                refreshQrCode()
                                            }}
                                        >Refresh Code</button>}
                                    </div>
                                </div>
                            }
                            <div className="card">
                                <p className="card-title">Checklist</p>
                                <div className="input-container">
                                    <p className="input-label">Inspections Checklist</p>
                                    <select className="select-input" value={locationDetails?.inspections_checklist_id} onChange={(e) => {
                                        setLocationDetails({
                                            ...locationDetails,
                                            inspections_checklist_id: e.target.value
                                        })
                                    }}>
                                        <option value="">Select Checklist</option>
                                        {checklistData.map((checklist, index) => {
                                            return (
                                                <option key={index} value={checklist.id}>{checklist.checklist_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="input-container">
                                    <p className="input-label">First-Aid Checklist</p>
                                    <select className="select-input" value={locationDetails?.first_aid_checklist_id} onChange={(e) => {
                                        setLocationDetails({
                                            ...locationDetails,
                                            first_aid_checklist_id: e.target.value
                                        })
                                    }}>
                                        <option value="">Select Checklist</option>
                                        {checklistData.map((checklist, index) => {
                                            return (
                                                <option key={index} value={checklist.id}>{checklist.checklist_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile(width) && <div
                    style={{ backgroundColor: "white", padding: 20, position: "sticky", bottom: 0, zIndex: 50, boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
                >
                    <div className="column gap-10" style={{ gap: 10 }}>

                        {location_id !== "create" &&
                            <button className="delete-button" style={{ width: "100%" }}
                                onClick={() => {
                                    deleteLocation()
                                }}>Delete</button>
                        }

                        <button className="primary-button" style={{ width: "100%" }}
                            onClick={() => {
                                if (location_id === "create") {
                                    saveLocation()
                                } else {
                                    updateLocation()
                                }
                            }}
                        >Save</button>
                    </div>
                </div>}
            </div>
        </div>
    )
}
