import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { isMobile } from '../utils/AppHelpers'

export default function ModalLayout({ children, modalTitle, isVisible, setIsVisible }) {
    const [width, setWidth] = useState(window.innerWidth);
    return (
        <>
            {isVisible && <div className="modal-container" key={"modal-container"} style={{ zIndex: 1000, maxWidth: isMobile(width) && "100dvw", }}
            // onClick={() => setIsVisible(false)}
            >
                <div className="modal-content" style={{ maxWidth: isMobile(width) && "100dvw", minWidth: isMobile(width) && 0, margin: isMobile(width) && 10 }}>
                    <div className="modal-header row alignCenter justifySplit" style={{ maxWidth: isMobile(width) && "100dvw" }}>
                        <h4 className="modal-title">{modalTitle}</h4>
                        <button className="backgroundLess" onClick={() => setIsVisible(false)}>
                            <IoClose />
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxWidth: isMobile(width) && "100dvw", width:"100%" }}>
                        {children}
                    </div>
                </div>
            </div>}
        </>
    )
}
