import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';

export default function AdminToolsReportsPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [incidentStartDate, setIncidentStartDate] = useState("")
    const [incidentEndDate, setIncidentEndDate] = useState("")

    const [suggestionStartDate, setSuggestionStartDate] = useState("")
    const [suggestionEndDate, setSuggestionEndDate] = useState("")

    const [complimentStartDate, setComplimentStartDate] = useState("")
    const [complimentEndDate, setComplimentEndDate] = useState("")

    const [firstAidStartDate, setFirstAidStartDate] = useState("")
    const [firstAidEndDate, setFirstAidEndDate] = useState("")

    const [inspectionStartDate, setInspectionStartDate] = useState("")
    const [inspectionEndDate, setInspectionEndDate] = useState("")

    const downloadReport = async (type) => {

        let start_date, end_date
        if (type === "incident") {
            start_date = incidentStartDate
            end_date = incidentEndDate
        } else if (type === "suggestion") {
            start_date = suggestionStartDate
            end_date = suggestionEndDate
        } else if (type === "compliment") {
            start_date = complimentStartDate
            end_date = complimentEndDate
        } 

        if (start_date === "" || end_date === "") {
            notify({ type: "error", message: "Please select a date range" })
            return
        }

        let endpoint = config.endpoint + "/admin/reports/download?start_date=" + start_date + "&end_date=" + end_date + "&type=" + type
        let fetchResult = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let result = await fetchResult.json()
        if (result.status === 200) {
            notify({ type: "success", message: result.message })
            window.open(result.data)
        } else {
            notify({ type: "error", message: result.message })
        }
    }

    const downloadInspection = async () => {
        if (inspectionStartDate === "" || inspectionEndDate === "") {
            notify({ type: "error", message: "Please select a date range" })
            return
        }
        let endpoint = config.endpoint + "/admin/inspections/download?start_date=" + inspectionStartDate + "&end_date=" + inspectionEndDate
        let fetchResult = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let result = await fetchResult.json()
        if (result.status === 200) {
            notify({ type: "success", message: result.message })
            window.open(result.data, "_blank")
        } else {
            notify({ type: "error", message: result.message })
        }
    }


    const downloadFirstAid = async () => {
        if (firstAidStartDate === "" || firstAidEndDate === "") {
            notify({ type: "error", message: "Please select a date range" })
            return
        }
        let endpoint = config.endpoint + "/admin/first-aid/download?start_date=" + firstAidStartDate + "&end_date=" + firstAidEndDate
        let fetchResult = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        let result = await fetchResult.json()
        if (result.status === 200) {
            notify({ type: "success", message: result.message })
            window.open(result.data, "_blank")
        } else {
            notify({ type: "error", message: result.message })
        }
    }



    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Tools"}/>
            <div className='container'>
                <AdminHeader title={"Overview"} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Data</p>
                        <p className="component-subtitle">Download</p>
                    </div>
                    {/* <button className='secondary-button'
                        onClick={() => {
                            navigate('/admin/tools/locations/create')
                        }}
                    >Add Location</button> */}
                </div>
                <div className="inner-container">
                    <div className="card">
                        <p className="card-title">Incident Reports</p>
                        <div className="row gap-10" style={{ maxWidth: 500 }}>
                            <div className="input-container">
                                <p className="input-label">From</p>
                                <input type="date" className="text-input" value={incidentStartDate} onChange={(e) => { setIncidentStartDate(e.target.value) }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">To</p>
                                <input type="date" className="text-input" value={incidentEndDate} onChange={(e) => { setIncidentEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="primary-button"
                                onClick={() => {
                                    downloadReport("incident")
                                }}
                            >Download</button>
                        </div>
                    </div>
                    <div className="card mt-10">
                        <p className="card-title">Compliment Reports</p>
                        <div className="row gap-10" style={{ maxWidth: 500 }}>
                            <div className="input-container">
                                <p className="input-label">From</p>
                                <input type="date" className="text-input" value={complimentStartDate} onChange={(e) => { setComplimentStartDate(e.target.value) }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">To</p>
                                <input type="date" className="text-input" value={complimentEndDate} onChange={(e) => { setComplimentEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="primary-button"
                                onClick={() => {
                                    downloadReport("compliment")
                                }}
                            >Download</button>
                        </div>
                    </div>
                    <div className="card mt-10">
                        <p className="card-title">Suggestions Reports</p>
                        <div className="row gap-10" style={{ maxWidth: 500 }}>
                            <div className="input-container">
                                <p className="input-label">From</p>
                                <input type="date" className="text-input" value={suggestionStartDate} onChange={(e) => { setSuggestionStartDate(e.target.value) }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">To</p>
                                <input type="date" className="text-input" value={suggestionEndDate} onChange={(e) => { setSuggestionEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="primary-button"
                                onClick={() => {
                                    downloadReport("suggestion")
                                }}
                            >Download</button>
                        </div>
                    </div>
                    <div className="card mt-10">
                        <p className="card-title">Inspections Reports</p>
                        <div className="row gap-10" style={{ maxWidth: 500 }}>
                            <div className="input-container">
                                <p className="input-label">From</p>
                                <input type="date" className="text-input" value={inspectionStartDate} onChange={(e) => { setInspectionStartDate(e.target.value) }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">To</p>
                                <input type="date" className="text-input" value={inspectionEndDate} onChange={(e) => { setInspectionEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="primary-button"
                                onClick={() => {
                                    downloadInspection()
                                }}
                            >Download</button>
                        </div>
                    </div>
                    <div className="card mt-10">
                        <p className="card-title">First Aid Reports</p>
                        <div className="row gap-10" style={{ maxWidth: 500 }}>
                            <div className="input-container">
                                <p className="input-label">From</p>
                                <input type="date" className="text-input" value={firstAidStartDate} onChange={(e) => { setFirstAidStartDate(e.target.value) }} />
                            </div>
                            <div className="input-container">
                                <p className="input-label">To</p>
                                <input type="date" className="text-input" value={firstAidEndDate} onChange={(e) => { setFirstAidEndDate(e.target.value) }} />
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="primary-button"
                                onClick={() => {
                                    downloadFirstAid()
                                }}
                            >Download</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
