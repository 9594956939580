import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { tidyDate } from '../../utils/AppHelpers';
import { ImCross } from 'react-icons/im';
import { FaTrash } from 'react-icons/fa';

export default function AdminFirstAidPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [firstAidData, setFirstAidData] = useState([])
    const [selectedReports, setSelectedReports] = useState([])
    const [pageState, setPageState] = useState("view") // view, manage

    useEffect(() => {
        fetchAllFirstAids()
    }, [])

    const fetchAllFirstAids = async () => {
        let endpoint = config.endpoint + "/first-aid/"
        let response = await fetch(endpoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
        let data = await response.json()
        if (data.status === 200) {
            setFirstAidData(data?.data?.reverse())
        }
    }

    const massDeleteReport = async () => {
        let endpoint = config.endpoint + `/first-aid/bulk`
        let fetchResponse = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ report_ids: selectedReports })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Successfully deleted reports")
            fetchAllFirstAids()
        } else {
            toast.error("Failed to delete reports")
        }
    }

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Reports"} />
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">First Aid</p>
                        <p className="component-subtitle">First Aid Reports</p>
                    </div>

                    {pageState === "manage" ? (
                        <div className="row gap-10 alignCenter">
                            <button className='backgroundLess row gap-10'
                                onClick={() => {
                                    setPageState(pageState === "view" ? "manage" : "view")
                                }}

                            >
                                <ImCross style={{}} />
                                Cancel</button>
                            <button className="delete-button" onClick={() => {
                                if (selectedReports.length === 0) {
                                    toast.error("Please select at least one report")
                                    return
                                }
                                massDeleteReport()
                            }}>Delete</button>
                        </div>) :

                        <button className='backgroundLess row gap-10'
                            onClick={() => {
                                setPageState(pageState === "view" ? "manage" : "view")
                            }}
                        >
                            <FaTrash style={{}} />
                            Manage</button>
                    }
                </div>
                <div className="inner-container">
                    {firstAidData?.length > 0 && firstAidData?.map((firstaid, index) => {
                        let color = "gray" // default
                        if (firstaid.status === "dismissed") {
                            color = "red"
                        } else if (firstaid.status === "open") {
                            color = "orange"
                        } else if (firstaid.status === "resolved") {
                            color = "green"
                        }

                        return (
                            <div className="card cursor-pointer mb-10"
                                style={{ width: "100%", border: (selectedReports?.includes(firstaid?.id) && pageState === "manage") ? "2px solid #00A4EF" : "2px solid transparent", }}
                                key={index}
                                onClick={() => {
                                    if (pageState === "manage") {
                                        if (selectedReports.includes(firstaid?.id)) {
                                            setSelectedReports(selectedReports.filter((item) => item !== firstaid?.id))
                                        } else {
                                            setSelectedReports([...selectedReports, firstaid?.id])
                                        }
                                    } else {
                                        navigate(`/admin/first-aid/id/${firstaid?.id}`)
                                    }
                                }}
                            >
                                <div className="row alignCenter justifySplit" style={{ display: "flex", alignItems: "center" }}>
                                    <div>
                                        <p className="card-title" style={{ margin: 0, padding: 0 }}>{firstaid?.case_id || firstaid?.id} - {tidyDate(firstaid?.submitted_at || firstaid?.createdAt)}</p>
                                        <p>{firstaid?.location_data?.location_name} @ {firstaid?.campus_data?.campus_name}</p>
                                    </div>
                                    <div className="row gap-10 alignCenter">
                                        <p style={{ backgroundColor: color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}>{firstaid.status}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* <pre>{JSON.stringify(firstAidData, null, 2)}</pre> */}
                </div>
            </div>
        </div>
    )
}
