import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import { isMobile, tidyDate } from '../../utils/AppHelpers';

export default function AdminCampusDetailPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { campus_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [campusDetails, setCampusDetails] = useState({})

    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    const [isLoaded, setIsLoaded] = useState(false)

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const fetchCampusById = async () => {
        if (campus_id === "create") return
        let endpoint = config.endpoint + `/campus/id/${campus_id}`
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            setCampusDetails(data.data)
            setIsLoaded(true)
        }
    }

    const createCampus = async () => {
        let endpoint = config.endpoint + `/campus/`
        let response = await fetch(endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...campusDetails,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Campus created successfully")
        } else {
            toast.error(data.message)
        }
    }

    const saveCampus = async () => {
        if (campus_id === "create") return
        let endpoint = config.endpoint + `/campus/id/${campus_id}`
        let response = await fetch(endpoint, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...campusDetails,
                id: campus_id,
            })
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Campus saved successfully")
        } else {
            toast.error(data.message)
        }
    }

    const deleteCampus = async () => {
        let endpoint = config.endpoint + `/campus/id/${campus_id}`
        let response = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Campus deleted successfully")
            navigate("/admin/tools/campus")
        } else {
            toast.error("Failed to delete campus")
        }
    }

    useEffect(() => {
        fetchCampusById()
    }, [])

    // useEffect(() => {
    //     if 
    //     saveCampus()
    // }, [campusDetails?.report_status])


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Locations"} />
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top, display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", alignItems: "center" }}>
                    <div>
                        <p className="component-title">{campus_id === "create" ? "Create" : "Edit"} Campus</p>
                        <p className="component-subtitle">{campus_id === "create" ? "Create a new campus" : "Edit an existing campus"}</p>
                    </div>
                </div>
                <div className="inner-container" style={{ height: "100%" }}>
                    <div className="row gap-10 alignTop" style={{ height: "100%" }}>
                        <div className="card">
                            <div className="row gap-10">
                                <div className="input-container ">
                                    <p className="card-title">Campus Name</p>
                                    <input className='text-input mt-10' placeholder='Campus Name' value={campusDetails?.campus_name} onChange={(e) => {
                                        setCampusDetails({ ...campusDetails, campus_name: e.target.value })
                                    }} />
                                </div>
                                <div className="input-container ">
                                    <p className="card-title">Campus Code</p>
                                    <input className='text-input mt-10' placeholder='Campus Code' value={campusDetails?.campus_code} onChange={(e) => {
                                        setCampusDetails({ ...campusDetails, campus_code: e.target.value?.replaceAll(" ", "-").toLowerCase() })
                                    }} />
                                </div>
                            </div>
                            <div className={`button-container justifyRight gap-10 ${isMobile(width) ? "column" : "row"}`} style={{ marginTop: isMobile(width) && 20 }}>
                                {campus_id !== "create" && <button className="delete-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                                    deleteCampus()
                                }}>
                                    Delete
                                </button>}
                                {campus_id === "create" ?
                                    <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                                        createCampus()
                                    }}>
                                        Create
                                    </button>
                                    : <button className="primary-button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                                        saveCampus()
                                    }}>
                                        Save
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
