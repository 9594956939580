import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ModalLayout from '../layout/ModalLayout';

const config = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypress: () => { },
    onKeypressEscape: () => { },
};

const IAPrompt = ({ title, message, buttons }) => {
    confirmAlert({
        ...config,
        title: title,
        message: message,
        buttons: buttons,
        customUI: ({ onClose }) => {
            return (
                <ModalLayout modalTitle={title} isVisible={true} setIsVisible={onClose}>
                    <div className="alignStart mb-20 w-100" style={{ zIndex: 1500 }}>
                        <p style={{ color: "black" }}>{message}</p>
                    </div>
                    <div className="column right alignEnd w-100">
                        <div className="row alignCenter gap-10">
                            {buttons.map((button, index) => {
                                return (
                                    <button key={index} className={index === buttons.length - 1 ? "primary-button" : "delete-button"} style={{ minWidth: 0 }} onClick={() => {
                                        button.onClick()
                                        onClose()
                                    }}>{button.label}</button>
                                )
                            })}
                        </div>
                    </div>
                </ModalLayout>
            );
        }
    });
}

export default IAPrompt