const config = {
    // Production
    bot_url: "http://t.me/sit_safe_bot",
    endpoint: "https://safety-platform-backend.sit.ourcodelab.com",
    local_url: "https://safety-platform.sit.ourcodelab.com",

    // Staging
    // endpoint: "https://safety-platform-backend-staging.sit.ourcodelab.com",
    // local_url: "https://safety-platform-staging.sit.ourcodelab.com",
    // bot_url: "https://t.me/sit_safety_test_bot",

    // Development
    // local_url: "http://192.168.1.56:3000",
    // endpoint: "http://192.168.1.56:4000",
    // bot_url: "https://t.me/sit_safety_test_bot",
    // local_url: "http://127.0.0.1:3000",
    // endpoint: "http://127.0.0.1:4000",


    version: "1.3.17 (02032025)",
    TELEGRAM_ADMIN_GROUP_ID: "-1001768257341",
    developer: "OurCodeLab LLP",
    website: "https://ourcodelab.com",


    feature_flags: {
        auth: {
            registration: {
                allow_registration: false
            }
        }
    }
}

export default config;
