import { ToastContainer, toast } from 'react-toastify';

const notify = ({message, type }) =>{

    const CONFIG = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        closeOnClick: true,
        draggable: false,
    }

    switch(type){
        case "success":
            toast.success(message, {...CONFIG})
            break

        case "error":
            toast.error(message, {...CONFIG})
            break
            
        case "warning":
            toast.warning(message, {...CONFIG})
            break

        default:
            toast(message, {...CONFIG})
            break

    }
}



export default notify