import React from 'react'
import { useState, useEffect } from 'react'
import config from '../../app-config'
import { useNavigate } from 'react-router-dom'

export default function GoPage() {
    const location = new URLSearchParams(window.location.search).get("location") || ""
    const name = new URLSearchParams(window.location.search).get("name") || ""
    const [timeLeft, setTimeLeft] = useState(10)
    useEffect(() => {
        if (timeLeft === 0) {
            navigate(`/user/report?location=${location}`)
            setTimeLeft(null)
        } else {
            // If you want to default to telegram when timeLeft is 2 seconds
            // if (timeLeft <= 2) {
            //     window.location.href = `${config.bot_url}?start=${location}`
            // }
        }

        // exit early when we reach 0
        if (!timeLeft) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    // TODO: check if location is in location list before rendering page

    const [email, setEmail] = useState("")
    const [telegramHandler, setTelegramHandler] = useState("")

    const navigate = useNavigate()

    return (
        <>
            <div>
                <div className={`row alignCenter justifyCenter center mt-40 column p-all`}>
                    <img src="/logo.png" alt="" style={{ height: 100, width: 100, objectFit: "contain" }} />
                    <p style={{ color: "black", fontWeight: "bold" }} className='mt-10'>Safety Reporting Platform</p>
                    <a href={`${config.bot_url}?start=${name}---${location}`} target="_blank" rel="noreferrer">
                        <button className="primary-button mt-20">Open Telegram</button>
                    </a>
                    <a href={`/user/report?location=${location}`}>
                        <button className="primary-button mt-20">Use Web</button>
                    </a>
                    <p className="mt-20">
                        Defaulting to Web in {timeLeft} seconds...
                    </p>
                </div>
            </div>
        </>
    )
}
