import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import config from '../../app-config';
import Switch from "react-switch";
import { isMobile } from '../../utils/AppHelpers';

export default function AdminGamificationSettingsPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    // const { inspections_checklist_id } = useParams()
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [isLoading, setIsLoading] = useState(true)

    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })

    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
    })

    const [settingsData, setSettingsData] = useState({
        points_per_submission: "",
        game_start: "",
        game_end: "",
        allow_points_collection: "",
        show_leaderboard: "",
        leaderboard_blackout_date: "",
    })

    const fetchSettingsById = async () => {
        setIsLoading(true)
        let endpoint = config.endpoint + `/game/settings`
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            setSettingsData(data.data)
            setIsLoading(false)
        }
    }

    const updateSettings = async () => {
        if (isLoading) return
        let endpoint = config.endpoint + `/game/settings`
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settingsData)
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Settings updated successfully")
        } else {
            toast.error("Error updating settings")
        }
    }

    const resetPoints = async () => {
        if (isLoading) return
        let endpoint = config.endpoint + `/game/points/reset`
        let response = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(settingsData)
        })
        let data = await response.json()
        if (data.status === 200) {
            toast.success("Points have been reset")
        } else {
            toast.error("Error resetting points")
        }
    }

    useEffect(() => {
        fetchSettingsById()
    }, [])

    useEffect(() => {
        updateSettings()
    }, [settingsData])


    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Gamification"} />
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Gamification</p>
                        <p className="component-subtitle">Settings</p>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="column gap-10">
                        <div className="card">
                            <p className="card-title">General</p>
                            <p className="card-subtitle">General settings for SIT Safety Platform Gamification</p>
                            <div className="input-container row alignCenter gap-10">
                                <p className="input-label" style={{ width: "100px" }}>Points per Submission</p>
                                <input className="text-input" type="number" value={settingsData.points_per_submission} onChange={(e) => {
                                    setSettingsData({
                                        ...settingsData,
                                        points_per_submission: e.target.value
                                    })
                                }} />
                            </div>
                            <div className="input-container row alignCenter gap-10">
                                <p className="input-label" style={{ width: "100px" }}>Allow points collection</p>
                                <Switch checked={settingsData.allow_points_collection} onChange={(e) => {
                                    setSettingsData({
                                        ...settingsData,
                                        allow_points_collection: e
                                    })
                                }} />
                            </div>
                            <div className="input-container row alignCenter gap-10">
                                <p className="input-label" style={{ width: "100px" }}>Reset Points</p>
                                <button className="secondary-button" onClick={() => {
                                    if (window.prompt("Are you sure you want to reset all points? Type 'reset' to confirm") !== "reset") return
                                    resetPoints()
                                }}>Reset Points</button>
                            </div>
                        </div>
                        <div className="card">
                            <p className="card-title">Gamification Period</p>
                            <p className="card-subtitle">Set the period of time for gamification</p>
                            <div className="row gap-10" style={{ flexWrap: isMobile(width) && "wrap", width: "100%" }}>
                                <div className="input-container" style={{ width: isMobile(width) && "100%" }}>
                                    <p className="input-label">Start Date</p>
                                    <input className="text-input" type="datetime-local" style={{width:"100%"}} value={settingsData.game_start} onChange={(e) => {
                                        setSettingsData({
                                            ...settingsData,
                                            game_start: e.target.value
                                        })
                                    }} />
                                </div>
                                <div className="input-container" style={{ width: isMobile(width) && "100%" }}>
                                    <p className="input-label">End Date</p>
                                    <input className="text-input" type="datetime-local" style={{width:"100%"}} value={settingsData.game_end} onChange={(e) => {
                                        setSettingsData({
                                            ...settingsData,
                                            game_end: e.target.value
                                        })
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <p className="card-title">Leaderboard</p>
                            <p className="card-subtitle">Settings for the leaderboard</p>
                            <div className="input-container row alignCenter gap-10">
                                <p className="input-label" style={{ width: "100px" }}>Show Leaderboard</p>
                                <Switch checked={settingsData.show_leaderboard} onChange={(e) => {
                                    setSettingsData({
                                        ...settingsData,
                                        show_leaderboard: e
                                    })
                                }} />
                            </div>
                            <div className="input-container row alignCenter gap-10">
                                <p className="input-label" style={{ width: "100px" }}>Leaderboard Blackout Date</p>
                                <input className="text-input" type="datetime-local" value={settingsData.leaderboard_blackout_date} onChange={(e) => {
                                    setSettingsData({
                                        ...settingsData,
                                        leaderboard_blackout_date: e.target.value
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
