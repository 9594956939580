import React, { useState, useEffect, useRef, useCallback } from 'react'
import AdminNavbar from '../../components/AdminNavbar'
import AdminHeader from '../../components/AdminHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification';
import { capitalised, isMobile, tidyDate } from '../../utils/AppHelpers';
import config from '../../app-config';
import { FaCross, FaTrash } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { MdCalendarMonth } from "react-icons/md";
import { DateRangePicker } from 'react-date-range';
import Select from 'react-select';

export default function AdminReportPage() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [reportData, setReportData] = useState([])
    const [top, setTop] = useState(document?.getElementById("admin-header")?.clientHeight)
    const [filter, setFilter] = useState(new URLSearchParams(window.location.search).get('filter') || "all")
    const [search, setSearch] = useState("")

    const [pageState, setPageState] = useState("view") // view, manage
    const [selectedReports, setSelectedReports] = useState({})
    const [locationFilter, setLocationFilter] = useState("")
    const [campusFilter, setCampusFilter] = useState("")
    const [locationData, setLocationData] = useState([])
    const [campusData, setCampusData] = useState([])
    const [startDateRange, setStartDateRange] = useState("")
    const [endDateRange, setEndDateRange] = useState("")
    const [showDateRange, setShowDateRange] = useState(false)
    const [dateRange, setDateRange] = useState(document.getElementById("date-range")?.getBoundingClientRect())
    const [statusSelected, setStatusSelected] = useState("")
    const [typeSelected, setTypeSelected] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const observerRef = useRef(null);
    const lastId = reportData.length ? reportData[reportData.length - 1].id : null;
    const [loadingMessage, setLoadingMessage] = useState("")

    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
    })


    document?.addEventListener("scroll", () => {
        setTop(document?.getElementById("admin-header")?.clientHeight)
        setDateRange(document.getElementById("date-range")?.getBoundingClientRect())
        setShowDateRange(false)
    })

    const fetchReports = useCallback(async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);
        let endpoint = config.endpoint + `/report?last_id=${lastId || ""}?` + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}&` : "") + (locationFilter ? `location_id=${locationFilter}&` : "") + (campusFilter ? `campus_id=${campusFilter}&` : "") + (statusSelected ? `status=${statusSelected}&` : "") + (typeSelected ? `type=${typeSelected}&` : "")

        try {
            let fetchResponse = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            let response = await fetchResponse.json()
            if (response.status === 200) {
                if (response.data.length === 0) {
                    setLoadingMessage(response.message)
                    setHasMore(false)
                } else {
                    setReportData((prevData) => [...prevData, ...response.data])
                }
            }
        } catch (error) {
            console.error("API Error:", error);
            setLoadingMessage("Failed to load data.")
        }

        setTimeout(() => setIsLoading(false), 1000);
    }, [isLoading, hasMore, lastId, startDateRange, endDateRange, locationFilter, campusFilter, statusSelected, typeSelected])

    const massDeleteReport = async () => {
        let endpoint = config.endpoint + `/report/bulk`
        let fetchResponse = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ report_ids: selectedReports?.["report"] })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Successfully deleted reports")
            fetchReports()
        } else {
            toast.error("Failed to delete reports")
        }
    }

    const massDeleteFirstAid = async () => {
        let endpoint = config.endpoint + `/first-aid/bulk`
        let fetchResponse = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ report_ids: selectedReports?.["firstaid"] })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Successfully deleted reports")
            fetchReports()
        } else {
            toast.error("Failed to delete reports")
        }
    }

    const massDeleteInspections = async () => {
        let endpoint = config.endpoint + `/inspection/bulk`
        let fetchResponse = await fetch(endpoint, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ report_ids: selectedReports?.["inspection"] })
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            toast.success("Successfully deleted reports")
            fetchReports()
        } else {
            toast.error("Failed to delete reports")
        }
    }

    const fetchLocations = async () => {
        let endpoint = config.endpoint + "/location"
        let fetchResponse = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setLocationData(response?.data)
        }
    }

    const fetchCampuses = async () => {
        let endpoint = config.endpoint + "/campus"
        let fetchResponse = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            setCampusData(response?.data)
        }
    }

    useEffect(() => {
        if (!reportData.length && !hasMore) return;

        const observer = new IntersectionObserver(
            ([list]) => {
                if (list.isIntersecting && !isLoading) fetchReports();

            },
            { rootMargin: "200px", threshold: 0.5 }
        );

        const currentObserverRef = observerRef.current;
        if (currentObserverRef) observer.observe(currentObserverRef);

        return () => observer.disconnect();
    }, [reportData, hasMore, isLoading, fetchReports, locationFilter, campusFilter, typeSelected, statusSelected, startDateRange, endDateRange])

    useEffect(() => {
        fetchLocations()
        fetchCampuses()
    }, [])

    return (
        <div className='row alignTop'>
            <ToastContainer />
            <AdminNavbar view={"Reports"} />
            <div className='container'>
                <AdminHeader title={""} />
                <div className="component" style={{ position: "sticky", top: top }}>
                    <div>
                        <p className="component-title">Report</p>
                        <p className="component-subtitle">{capitalised(filter)} report</p>
                    </div>

                    {pageState === "manage" ? (
                        <div className="row gap-10 alignCenter">
                            <button className='backgroundLess row gap-10'
                                onClick={() => {
                                    setPageState(pageState === "view" ? "manage" : "view")
                                }}

                            >
                                <ImCross style={{}} />
                                Cancel</button>
                            <button className="delete-button" onClick={() => {
                                if (selectedReports.length === 0) {
                                    toast.error("Please select at least one report")
                                    return
                                }
                                massDeleteReport()
                                massDeleteFirstAid()
                                massDeleteInspections()
                            }}>Delete</button>
                        </div>) :

                        <button className='backgroundLess row gap-10'
                            onClick={() => {
                                setPageState(pageState === "view" ? "manage" : "view")
                            }}
                        >
                            <FaTrash style={{}} />
                            Manage</button>
                    }
                </div>
                <div className="inner-container">
                    {/* <pre>{JSON.stringify(reportData,null,2)}</pre> */}
                    <input
                        className="text-input"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    />
                    <div className={`${isMobile(width) ? "column" : "row"} gap-10 mt-10 mb-10`} style={{ display: "flex", flexWrap: "wrap" }}>
                        {/* <div className={`${isMobile(width) ? "row" : "row"} gap-10`} style={{ display: "flex", flexWrap: "wrap" }}> */}
                        <div className="row gap-10">
                            <Select
                                value={{
                                    value: campusFilter,
                                    label: campusData?.find((campus) => campus.id === campusFilter)?.campus_name || "All Campuses"
                                }}
                                onChange={(e) => {
                                    setCampusFilter(e.value)
                                }}
                                options={[
                                    { value: "", label: "All Campuses" },
                                    ...campusData?.map((campus, index) => {
                                        return {
                                            value: campus?.id,
                                            label: campus?.campus_name
                                        }
                                    })]}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        color: "black"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                }}
                            />
                            <Select
                                value={{
                                    value: locationFilter,
                                    label: locationData?.find((location) => location.id === locationFilter)?.location_name || "All Locations"
                                }}
                                onChange={(e) => {
                                    setLocationFilter(e.value)
                                }}
                                options={[
                                    { value: "", label: "All Locations" },
                                    ...locationData?.map((location, index) => {
                                        return {
                                            value: location?.id,
                                            label: `[${location?.location_code}] ${location?.location_name}`
                                        }
                                    })]}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        color: "black"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        width: 200,
                                    }),
                                }}
                            />
                            <Select
                                value={{
                                    value: typeSelected,
                                    label: capitalised(typeSelected ? typeSelected : "All Reports")
                                }}
                                onChange={(e) => {
                                    setTypeSelected(e.value)
                                }}
                                options={[
                                    { value: "", label: "All Reports" },
                                    { value: "incident", label: "Incident" },
                                    { value: "suggestion", label: "Suggestion" },
                                    { value: "compliment", label: "Compliment" },
                                    { value: "firstaid", label: "First Aid" },
                                    { value: "inspection", label: "Inspection" },
                                ]}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        color: "black"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                }}
                            />
                            <Select
                                value={{
                                    value: statusSelected,
                                    label: capitalised(statusSelected ? statusSelected : "All Status")
                                }}
                                onChange={(e) => {
                                    setStatusSelected(e.value)
                                }}
                                options={[
                                    { value: "", label: "All Status" },
                                    { value: "open", label: "Open" },
                                    { value: "pending", label: "Pending" },
                                    { value: "resolved", label: "Resolved" },
                                    { value: "dismissed", label: "Dismissed" },
                                ]}
                                styles={{
                                    menuList: (provided) => ({
                                        ...provided,
                                        color: "black"
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        width: 200
                                    }),
                                }}
                            />


                        </div>
                        <div className="row gap-10 alignCenter text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }} id="date-range">
                            <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{startDateRange ? tidyDate(startDateRange) : "Start Date"}</p>
                            <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                        </div>
                        <div className="row gap-10 alignCenter text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }}>
                            <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{endDateRange ? tidyDate(endDateRange) : "End Date"}</p>
                            <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                        </div>
                        {showDateRange &&
                            <div
                                style={{ position: "absolute", zIndex: 1000, top: dateRange?.top + 50, left: dateRange?.left, }}
                            >
                                <DateRangePicker
                                    ranges={[
                                        {
                                            startDate: new Date(startDateRange || Date.now()),
                                            endDate: new Date(endDateRange || Date.now()),
                                            key: 'selection',
                                        }
                                    ]}

                                    showPreview={false}
                                    showDateDisplay={false}
                                    direction='horizontal'
                                    maxDate={new Date(Date.now())}
                                    months={2}
                                    onChange={(item) => {
                                        setStartDateRange(new Date(item.selection.startDate).toISOString())
                                        setEndDateRange(new Date(item.selection.endDate).toISOString())
                                    }}

                                />
                            </div>
                        }
                        {showDateRange && <div className="row alignCenter gap-10 cursor-pointer"
                            onClick={() => {
                                setShowDateRange(false)
                            }}
                        >
                            <p>Close Calendar</p>
                        </div>}

                        {startDateRange || endDateRange || locationFilter || campusFilter ?
                            <div className="row alignCenter gap-10 cursor-pointer"
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => {
                                    setStartDateRange("")
                                    setEndDateRange("")
                                    setLocationFilter("")
                                    setCampusFilter("")
                                }}
                            >
                                <p>Clear Filter</p>
                            </div> : null}
                        {/* </div> */}
                    </div>
                    {/* <div className="row gap-10 alignCenter mb-20 mt-10">
                        <div className={`${isMobile(width) ? "row" : "row"} gap-10`} style={{ display: "flex",  }}>
                            <div className="row gap-10 alignTop">
                                <Select
                                    value={{
                                        value: campusFilter,
                                        label: campusData?.find((campus) => campus.id === campusFilter)?.campus_name || "All Campuses"
                                    }}
                                    onChange={(e) => {
                                        setCampusFilter(e.value)
                                    }}
                                    options={campusData?.map((campus, index) => {
                                        return {
                                            value: campus?.id,
                                            label: campus?.campus_name
                                        }
                                    })}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black",
                                            zIndex: 1000
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200,
                                            zIndex: 1000
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200,
                                            zIndex: 1000
                                        }),
                                        
                                    }}
                                />
                                <Select
                                    value={{
                                        value: locationFilter,
                                        label: locationData?.find((location) => location.id === locationFilter)?.location_name || "All Locations"
                                    }}
                                    onChange={(e) => {
                                        setLocationFilter(e.value)
                                    }}
                                    options={locationData?.map((location, index) => {
                                        return {
                                            value: location?.id,
                                            label: `[${location?.location_code}] ${location?.location_name}`
                                        }
                                    })}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200,
                                        }),
                                    }}
                                />
                                <Select
                                    value={{
                                        value: typeSelected,
                                        label: capitalised(typeSelected ? typeSelected : "All Reports")
                                    }}
                                    onChange={(e) => {
                                        setTypeSelected(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Reports" },
                                        { value: "incident", label: "Incident" },
                                        { value: "suggestion", label: "Suggestion" },
                                        { value: "compliment", label: "Compliment" },
                                        { value: "firstaid", label: "First Aid" },
                                        { value: "inspection", label: "Inspection" },
                                    ]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                    }}
                                />
                                <Select
                                    value={{
                                        value: statusSelected,
                                        label: capitalised(statusSelected ? statusSelected : "All Status")
                                    }}
                                    onChange={(e) => {
                                        setStatusSelected(e.value)
                                    }}
                                    options={[
                                        { value: "", label: "All Status" },
                                        { value: "open", label: "Open" },
                                        { value: "pending", label: "Pending" },
                                        { value: "resolved", label: "Resolved" },
                                        { value: "dismissed", label: "Dismissed" },
                                    ]}
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            color: "black"
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                        container: (provided) => ({
                                            ...provided,
                                            width: 200
                                        }),
                                    }}
                                />
                            </div>


                            <div className="row gap-10 alignTop text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", }} id="date-range">
                                <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{startDateRange ? tidyDate(startDateRange) : "Start Date"}</p>
                                <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                            </div>
                            <div className="row gap-10 alignTop text-input" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", }}>
                                <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{endDateRange ? tidyDate(endDateRange) : "End Date"}</p>
                                <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                            </div>
                            {showDateRange &&
                                <div
                                    style={{ position: "absolute", zIndex: 1000, top: dateRange?.top + 50, left: dateRange?.left, }}
                                >
                                    <DateRangePicker
                                        ranges={[
                                            {
                                                startDate: new Date(startDateRange || Date.now()),
                                                endDate: new Date(endDateRange || Date.now()),
                                                key: 'selection',
                                            }
                                        ]}

                                        showPreview={false}
                                        showDateDisplay={false}
                                        direction='horizontal'
                                        maxDate={new Date(Date.now())}
                                        months={2}
                                        onChange={(item) => {
                                            setStartDateRange(new Date(item.selection.startDate).toISOString())
                                            setEndDateRange(new Date(item.selection.endDate).toISOString())
                                        }}

                                    />
                                </div>
                            }
                            {showDateRange && <div className="row alignCenter gap-10 cursor-pointer"
                                onClick={() => {
                                    setShowDateRange(false)
                                }}
                            >
                                <p>Close Calendar</p>
                            </div>}

                            {startDateRange || endDateRange || locationFilter || campusFilter ?
                                <div className="row alignCenter gap-10 cursor-pointer"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                        setStartDateRange("")
                                        setEndDateRange("")
                                        setLocationFilter("")
                                        setCampusFilter("")
                                    }}
                                >
                                    <p>Clear Filter</p>
                                </div> : null}
                        </div>
                    </div> */}
                    {reportData.length > 0 ? (
                        reportData?.map((report, index) => {

                            if (!report?.report_status) {
                                report.report_status = report?.status
                            }

                            let color = "gray" // default
                            if (report.report_status === "open") {
                                color = "red"
                            } else if (report.report_status === "pending") {
                                color = "orange"
                            } else if (report.report_status === "resolved") {
                                color = "green"
                            }

                            let incident_color = "gray" // default
                            if (report.type === "incident") {
                                incident_color = "red"
                            } else if (report.type === "suggestion") {
                                incident_color = "orange"

                            } else if (report.type === "compliment") {
                                incident_color = "green"

                            } else if (report.type === "firstaid") {
                                incident_color = "blue"
                            } else if (report.type === "inspection") {
                                incident_color = "purple"
                            }

                            if (report.type === 'incident' || report.type === 'suggestion' || report.type === 'compliment') {
                                report.overall_type = "report"
                            } else if (report.type === 'firstaid') {
                                report.overall_type = "firstaid"
                            } else if (report.type === 'inspection') {
                                report.overall_type = "inspection"
                            }

                            if (filter !== "all" && report.type !== filter) return null
                            if (search !== "" && !JSON.stringify(report)?.toLowerCase().includes(search.toLowerCase())) return null
                            return (
                                <div key={index} className="card mb-10 cursor-pointer"
                                    style={{ width: "100%", border: (selectedReports[report.overall_type]?.includes(report?.id) && pageState === "manage") ? "2px solid #00A4EF" : "2px solid transparent", }}
                                    onClick={() => {
                                        if (pageState === "manage") {
                                            let newSelectedReports = { ...selectedReports }
                                            if (newSelectedReports[report.overall_type]?.includes(report?.id)) {
                                                newSelectedReports[report.overall_type] = newSelectedReports[report.overall_type]?.filter((id) => id !== report?.id)
                                            } else {
                                                if (!newSelectedReports[report.overall_type]) {
                                                    newSelectedReports[report.overall_type] = []
                                                }
                                                newSelectedReports[report.overall_type].push(report?.id)
                                            }
                                            setSelectedReports(newSelectedReports)
                                        } else {

                                            if (report.type === "incident" || report.type === "suggestion" || report.type === "compliment" || report.type === "report") {
                                                navigate(`/admin/reports/id/${report?.id}`)
                                            } else if (report.type === "firstaid") {
                                                navigate(`/admin/first-aid/id/${report?.id}`)
                                            } else if (report.type === "inspection") {
                                                navigate(`/admin/inspections/id/${report?.id}`)
                                            }
                                        }
                                    }}
                                >
                                    <div className={`${isMobile(width) ? "column" : "row alignCenter justifySplit"}  `} style={{ display: "flex", width: "100%", }}>
                                        <div style={{ width: isMobile(width) && "100%" }}>
                                            <p className="card-title" style={{ margin: 0, padding: 0 }}>{report?.case_id ? report?.case_id : report?.id} - {tidyDate(report?.createdAt)}</p>
                                            <p className="card-title" style={{ marginBottom: 0, color: "gray", padding: 0 }}>{report?.location_data?.location_name} @ {report?.campus_data?.campus_name}</p>
                                        </div>
                                        <div className="row gap-10 alignCenter" style={{ marginTop: isMobile(width) && 10, justifyContent: isMobile(width) && "right", width: isMobile(width) && "100%" }}>
                                            <p style={{ backgroundColor: incident_color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}>{report.type}</p>
                                            <p style={{ backgroundColor: color, padding: "5px 20px", fontSize: 13, color: "white", fontWeight: "bold" }}>{report.report_status}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })) : (
                        <p className="component-title text-center">{loadingMessage}</p>
                    )}

                    <div ref={observerRef}>
                        {isLoading && reportData.length > 0 && <p className="component-title text-center">Loading data...</p>}
                    </div>
                </div>
            </div>
        </div >
    )
}
