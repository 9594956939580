import React from 'react'
import { useState } from 'react'
import config from '../../app-config'
import { useNavigate, useParams } from 'react-router-dom'
import notify from '../../utils/IANotification'

export default function UserAuthPage({ state }) {
    const [pageState, setPageState] = useState(state ? state : "login")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    let token = useParams().token

    const [email, setEmail] = useState("")
    const [telegramHandler, setTelegramHandler] = useState("")

    const navigate = useNavigate()

    const login = async () => {
        let endpoint = config.endpoint + "/auth/public-login"
        let res = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })

        let response = await res.json()
        if (response.status === 200) {
            localStorage.setItem("token", res.token)
            localStorage.setItem("user", JSON.stringify(res.user))
            navigate("/user/home")
        } else {
            notify({ message: response.message, type: "error" })
        }
    }

    const register = async () => {
        // TODO: Add validation
        if (password.length < 8) return notify({ message: "Password must be at least 8 characters long", type: "error" })
        if (!/\d/.test(password)) return notify({ message: "Password must contain at least one number", type: "error" })
        if (!/[A-Z]/.test(password)) return notify({ message: "Password must contain at least one uppercase letter", type: "error" })
        if (!/[a-z]/.test(password)) return notify({ message: "Password must contain at least one lowercase letter", type: "error" })
        if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) return notify({ message: "Password must contain at least one special character", type: "error" })
        if (password.includes(username)) return notify({ message: "Password must not contain username", type: "error" })

        let endpoint = config.endpoint + "/auth/public-register"
        let res = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password,
                email: email,
                telegramHandler: telegramHandler
            })
        })
        let response = await res.json()
        if (response.status === 200) {
            localStorage.setItem("token", res.token)
            localStorage.setItem("user", JSON.stringify(res.user))
            setPageState("login")
            notify({ message: "Your account has been created", type: "success" })
        } else {
            notify({ message: response.message, type: "error" })
        }
    }

    const requestEmail = async () => {
        let endpoint = config.endpoint + "/auth/reset"
        let res = await fetch(endpoint, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        })
        let response = await res.json()
        if (response.status === 200) {
            notify({ message: "Reset password email has been sent", type: "success" })
            setPageState("login")
        } else {
            notify({ message: response.message, type: "error" })
        }
    }


    const setPasswordForRecovery = async () => {
        let endpoint = config.endpoint + "/auth/reset"
        let res = await fetch(endpoint, {
            method: "PUT",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                password: password,
                token: token
            })
        })
        let response = await res.json()
        if (response.status === 200) {
            notify({ message: "Password has been reset", type: "success" })
            setPageState("login")
        } else {
            notify({ message: response.message, type: "error" })
        }
    }

    return (
        <div>
            <div className={`row alignCenter center mt-40 column p-all`}>
                <img src="/logo.png" alt="" srcset="" style={{ height: 100, width: 100, objectFit: "contain" }} />
                <p style={{ color: "black", fontWeight: "bold" }} className='mt-10'>Safety Reporting Platform</p>
            </div>
            {pageState === "login" && <div className='p-all'>
                <div className="input-container">
                    <p className="input-label">Username</p>
                    <input type="text" className="text-input" value={username} onChange={(e) => setUsername(e.target.value)}  onKeyDown={(e)=>{
                        if (e.key === "Enter") {
                            document.getElementById("password").focus()
                        }
                    }}/>
                </div>
                <div className="input-container">
                    <p className="input-label">Password</p>
                    <input type="password" id='password' className="text-input" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e)=>{
                        if (e.key === "Enter") login()
                    }} />
                </div>

                <div className="column alignCenter center mt-20">
                    <button className="primary-button" onClick={() => {
                        login()
                    }}>Login</button>

                    <button className="backgroundLess mt-10" style={{ fontSize: 12, color: "grey" }}
                        onClick={() => {
                            setPageState("forgotPassword")
                        }}
                    >
                        Forgot password?
                    </button>

                    <button className="backgroundLess mt-10" style={{ fontSize: 12, color: "grey" }}
                        onClick={() => {
                            setPageState("register")
                        }}
                    >
                        Do not have an account yet? Register here!
                    </button>
                </div>
            </div>}

            {pageState === "register" && <div className='p-all'>
                <div className="input-container">
                    <p className="input-label">Username</p>
                    <input type="text" className="text-input" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="input-container">
                    <p className="input-label">Email (optional)</p>
                    <input type="text" className="text-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="input-container">
                    <p className="input-label">Telegram Handler (optional)</p>
                    <input type="text" className="text-input" value={telegramHandler} onChange={(e) => setTelegramHandler(e.target.value)} />
                </div>
                <div className="input-container">
                    <p className="input-label">Password</p>
                    <input type="password" className="text-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="column alignCenter center mt-20">
                    <button className="primary-button" onClick={() => {
                        register()
                    }}>Register</button>
                    <button className="backgroundLess mt-10" style={{ fontSize: 12, color: "grey" }}
                        onClick={() => {
                            setPageState("login")
                        }}
                    >
                        Already have an account? Login here!
                    </button>
                </div>
            </div>}

            {pageState === "forgotPassword" && <div className='p-all'>
                <div className="input-container">
                    <p className="input-label">Email</p>
                    <input type="text" className="text-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="column alignCenter center mt-20">
                    <button className="primary-button" onClick={() => {
                        requestEmail()
                    }}>Send reset password email</button>
                    <button className="backgroundLess mt-10" style={{ fontSize: 12, color: "grey" }}
                        onClick={() => {
                            setPageState("login")
                        }}
                    >
                        Already have an account? Login here!
                    </button>
                </div>
            </div>}

            {pageState === "reset-password" && <div className='p-all'>
                <div className="input-container">
                    <p className="input-label">New Password</p>
                    <input type="password" className="text-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="column alignCenter center mt-20">
                    <button className="primary-button" onClick={() => {
                        setPasswordForRecovery()
                    }}>Set Password</button>
                </div>
            </div>
            }
        </div>
    )
}
