import React from 'react'
import { MdExitToApp } from 'react-icons/md'
import { logout, useLogin } from '../utils/AppFunctions'
import { useNavigate } from 'react-router-dom'

export default function UserHeader() {
    const navigate = useNavigate()
    const isLogin = useLogin("user")
    if (!isLogin) {
        navigate("/")
    }
    return (
        <div style={{ width: "100%", backgroundColor: "black", position: "sticky", top: 0, padding: "10px", alignItems: "center", zIndex: 20 }} className='row justifySplit alignCenter' id='admin-header'>
            <div>
                <img src="/logo.png" alt="" style={{ height: 50, width: 50, objectFit: "contain",}} />
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "center", gap: 10, cursor: "pointer" }}
                    onClick={() => {
                        logout()
                    }}
                >
                    <MdExitToApp size={20} color="white" />
                    <p style={{color:"white"}}>LOGOUT</p>
                </div>
            </div>
        </div>
    )
}
