import React, { useEffect, useState } from 'react'
import { FaCamera, FaClipboard, FaFile, FaHelmetSafety, FaMicrophone, FaRegThumbsUp, } from 'react-icons/fa6'
import { FaFirstAid, FaThumbsUp, FaTools } from 'react-icons/fa'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import config from '../../app-config';
import Webcam from "react-webcam";
import { useNavigate } from 'react-router-dom';
import { useFetchAuthorisation, useFetchUser, useLogin } from '../../utils/AppFunctions';
import notify from '../../utils/IANotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdHome } from 'react-icons/md';
import IAPrompt from '../../utils/IAPrompt';



export default function UserReportingPage() {
    const [isSending, setIsSending] = useState(false)
    const navigate = useNavigate()
    const user_data = useFetchUser()
    const location = new URLSearchParams(window.location.search).get("location") || ""
    const type = new URLSearchParams(window.location.search).get("type") || ""
    const [pageState, setPageState] = useState(type !== "" ? type : "selection") // selection, incident, compliment, suggestion, firstaid, inspection

    const [reportData, setReportData] = useState({
        description: "",
        type: "",
        location: location,
        files: [],
        contact_information: "",
        reporter_name: "",
        // latitude: navigator.geolocation?.getCurrentPosition()?.coords?.latitude || 0,
        // longitude: navigator.geolocation?.getCurrentPosition()?.coords?.longitude || 0,
    })

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setReportData({ ...reportData, latitude: position.coords.latitude, longitude: position.coords.longitude })
            })
        }
    }, [])


    const [ALLOW_SUBMISSION_TYPE, setALLOW_SUBMISSION_TYPE] = useState({
        "incident": true,
        "compliment": false,
        "suggestion": false,
        "firstaid": true,
        "inspection": true,
    })

    const [checklistUserData, setChecklistUserData] = useState({
        inspections_checklist_id: "",
        location_code: location,
        inspection_status: "pending",
        inspection_response: [],
        inspection_remarks: "",
        response: [],
        remarks: "",
        first_aid_checklist_id: "",
    })

    const [locationName, setLocationName] = useState("")
    const [checklistData, setChecklistData] = useState({})

    const isAuthorised = useFetchAuthorisation("staff")
    const isLogin = useLogin()


    const [inputType, setInputType] = useState("") // camera, audio, text
    const [filesUploaded, setFilesUploaded] = useState([])

    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder();


    useEffect(() => {
        if (!recordingBlob) return;
        handleAudio(recordingBlob)
    }, [recordingBlob])

    function handleTakePhoto(dataUri) {
        // Save photo to state
        // convert from base64 to file

        const file = new File([dataUri], "image.png", { type: "image/png" })


        setReportData({ ...reportData, files: [...reportData.files, file] })
        console.log('takePhoto');
        setInputType("")
    }

    function handleAudio(blob) {
        const file = new File([blob], "audio.mp3", { type: "audio/mp3" })
        setReportData({ ...reportData, files: [...reportData.files, file] })
        console.log('handleAudio');
        setInputType("")
    }

    const submitReport = async () => {
        if (isSending) return
        // if (reportData.files.length === 0) notify({ type: "error", message: "Please attach at least one file" })
        if (reportData.description === "") notify({ type: "error", message: "Please enter a description" })
        if (reportData.description.length > 1000) notify({ type: "error", message: "Description cannot be more than 1000 characters" })
        if (reportData?.reporter_name?.length > 100) notify({ type: "error", message: "Please enter a valid name" })
        if (reportData?.contact_information?.length > 100 || reportData?.contact_information?.length < 5) notify({ type: "error", message: "Please enter a valid contact information" })



        const formData = new FormData();
        let endpoint = config.endpoint + "/report/submit"
        formData.append("description", reportData.description)
        formData.append("type", pageState)
        formData.append("location", reportData.location || location)
        formData.append("reporter_name", reportData.reporter_name)
        formData.append("contact_information", reportData.contact_information)
        formData.append("longitude", reportData?.longitude)
        formData.append("latitude", reportData?.latitude)
        reportData.files.forEach(file => {
            formData.append("files", file)
        })

        setIsSending(true)

        const response = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            body: formData,
        })
        const data = await response.json()
        if (data.status === 200) {
            setReportData({
                description: "",
                type: "",
                location: "",
                files: [],
                contact_information: ""
            })
            setPageState("success")
            notify({ type: "success", message: "Report submitted successfully" })
        } else {
            notify({ type: "error", message: `Report submission failed: ${data.message}` })
        }
        setIsSending(false)
    }

    const fetchLocationName = async () => {
        let endpoint = config.endpoint + "/location/code/" + location
        let response = await fetch(endpoint, {
            method: 'GET',
            credentials: 'include',
        })
        let data = await response.json()
        if (data.status === 200) {
            setLocationName(data.data)
        }
    }

    const fetchChecklistByLocation = async () => {
        let endpoint
        if (pageState === "firstaid") {
            endpoint = config.endpoint + "/checklist/location/first-aid/" + location
        } else {
            endpoint = config.endpoint + "/checklist/location/inspections/" + location
        }
        // const endpoint = config.endpoint + "/checklist/location/" + location
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "GET",
        })
        const data = await response.json()
        if (data.status === 200) {
            setChecklistData(data.data)
            if (pageState === "firstaid") {
                setChecklistUserData({ ...checklistUserData, first_aid_checklist_id: data.data.id, response: data?.data?.checklist_items?.map(item => { return { ...item, selection: (item?.item_type === "checkbox") ? [...Array.from("1".repeat(item?.item_options?.length)).map(item => "")] : "" } }) })
            } else {
                setChecklistUserData({ ...checklistUserData, inspections_checklist_id: data.data.id, inspection_response: data?.data?.checklist_items?.map(item => { return { ...item, selection: (item?.item_type === "checkbox") ? [...Array.from("1".repeat(item?.item_options?.length)).map(item => "")] : "" } }) })
            }
        }
    }

    const submitInspection = async () => {
        if (isSending) return

        setIsSending(true)
        const endpoint = config.endpoint + "/inspection/submit"
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ...checklistUserData,
                type: pageState
            })
        })
        const data = await response.json()
        if (data.status === 200) {
            setChecklistUserData({
                inspections_checklist_id: "",
                location_code: location,
                inspection_status: "pending",
                inspection_response: [],
                inspection_remarks: "",
            })
            setPageState("success")
            notify({ type: "success", message: "Inspection submitted successfully" })
        } else {
            notify({ type: "error", message: "Inspection submission failed" })
        }
        setIsSending(false)
    }

    const submitFirstAid = async () => {
        if (isSending) return

        setIsSending(true)
        const endpoint = config.endpoint + "/first-aid/submit"
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ...checklistUserData,
                type: pageState
            })
        })
        const data = await response.json()
        if (data.status === 200) {
            setChecklistUserData({
                inspections_checklist_id: "",
                location_code: location,
                inspection_status: "pending",
                inspection_response: [],
                inspection_remarks: "",
            })
            setPageState("success")
            notify({ type: "success", message: "First Aid submitted successfully" })
        } else {
            notify({ type: "error", message: "First Aid submission failed" })
        }
        setIsSending(false)
    }



    useEffect(() => {
        fetchChecklistByLocation()
        fetchLocationName()
        load_available_submission_type()
        if (type) {
            setPageState(type)
        }
    }, [location])


    const uploadFileInspection = async (id, index) => {
        const formData = new FormData();
        let endpoint = config.endpoint + "/inspection/file/upload"
        let files = document.getElementById(id).files
        let newFiles = []
        for (let i = 0; i < files.length; i++) {
            newFiles.push(files[i])
        }
        newFiles.forEach(file => {
            formData.append("files", file)
        })
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            body: formData,
        })
        const data = await response.json()
        if (data.status === 200) {
            let newInspectionData = { ...checklistUserData }
            newInspectionData.inspection_response[index].item_value = data.data
            setChecklistUserData(newInspectionData)
            notify({ type: "success", message: "File uploaded successfully" })
        } else {
            notify({ type: "error", message: "File upload failed" })
        }
    }

    const uploadFileFirstAid = async (id, index) => {
        const formData = new FormData();
        let endpoint = config.endpoint + "/first-aid/file/upload"
        let files = document.getElementById(id).files
        let newFiles = []
        for (let i = 0; i < files.length; i++) {
            newFiles.push(files[i])
        }
        newFiles.forEach(file => {
            formData.append("files", file)
        })
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "POST",
            body: formData,
        })
        const data = await response.json()
        if (data.status === 200) {
            let newChecklistData = { ...checklistUserData }
            newChecklistData.response[index].item_value = data.data
            setChecklistUserData(newChecklistData)
            notify({ type: "success", message: "File uploaded successfully" })
        } else {
            notify({ type: "error", message: "File upload failed" })
        }
    }


    const load_available_submission_type = async () => {
        const endpoint = config.endpoint + "/config/available/submission"
        const response = await fetch(endpoint, {
            credentials: "include",
            method: "GET",
        })
        const data = await response.json()
        if (data.status === 200) {
            setALLOW_SUBMISSION_TYPE({
                "incident": data.data.allow_report == 1 ? true : false,
                "compliment": data.data.allow_compliment == 1 ? true : false,
                "suggestion": data.data.allow_suggestions == 1 ? true : false,
                "firstaid": data.data.allow_firstaid == 1 ? true : false,
                "inspection": data.data.allow_inspection == 1 ? true : false,
            })
        }
    }


    // useEffect(() => {
    //     fetchAuthorisation()
    // }, [location])


    useEffect(() => {
        fetchChecklistByLocation()
    }, [pageState])

    return (
        <>
            <ToastContainer />
            {inputType === "camera" &&
                <div className="center alignCenter column" style={{ height: "100vh", backgroundColor: "black" }}>
                    <Camera
                        isFullscreen
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        // imageType={IMAGE_TYPES.JPG}
                        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    />
                    {/* <Webcam height={600} width={600} /> */}
                </div>

            }
            {inputType === "audio" &&
                <div className="center alignCenter column" style={{ height: "100vh", backgroundColor: "white" }}>
                    {isRecording ? <button className='recording circle' onClick={() => {
                        stopRecording()
                        setInputType("")
                    }}>Pause</button> : <button onClick={startRecording}>Start</button>}
                </div>
            }

            {inputType == "" && <div>
                <div className="row alignCenter center mt-40 column p-all">
                    <img src="/logo.png" alt="" srcset="" style={{ height: 100, width: 100, objectFit: "contain" }} />
                    <p style={{ color: "black", fontWeight: "bold" }} className='mt-10'>Safety Reporting Platform</p>
                    {!isLogin ? <button className="backgroundLess"
                        onClick={() => {
                            navigate("/user/login")
                        }}
                    >Login</button>
                        :
                        <p className='mt-10'>Hello {user_data?.username || `@${user_data?.telegram}` || user_data?.email}, welcome back!</p>
                    }
                </div>

                {pageState === "selection" && <div>
                    <div className='mt-40 center alignCenter gap-10 flex'>
                        {/* Incident/Safety Hazard */}
                        <div className='alignCenter center flex column textCenter' style={{ backgroundColor: "gray", height: 150, width: 150, opacity: ALLOW_SUBMISSION_TYPE["incident"] ? 1 : 0.5 }}
                            onClick={() => {
                                if (!ALLOW_SUBMISSION_TYPE["incident"]) {
                                    notify({ type: "error", message: "This reporting method will be available soon, please try another type of reporting." })
                                    return
                                }
                                setPageState("incident")
                            }}
                        >
                            <FaHelmetSafety color="white" size={30} />
                            <p className='mt-10' style={{ color: "white" }}>Incident/Safety Hazard</p>
                        </div>

                        {/* Compliment */}
                        <div className='alignCenter center flex column textCenter' style={{ backgroundColor: "gray", height: 150, width: 150, opacity: ALLOW_SUBMISSION_TYPE["compliment"] ? 1 : 0.5 }}
                            onClick={() => {
                                if (!ALLOW_SUBMISSION_TYPE["compliment"]) {
                                    notify({ type: "error", message: "This reporting method will be available soon, please try another type of reporting." })
                                    return
                                }
                                setPageState("compliment")
                            }}
                        >
                            <FaRegThumbsUp color="white" size={30} />
                            <p className='mt-10' style={{ color: "white" }}>Compliment</p>
                        </div>
                    </div>
                    <div className='mt-10 center alignCenter gap-10 flex'>


                        {/* Suggestion Feedback */}
                        <div className='alignCenter center flex column textCenter' style={{ backgroundColor: "gray", height: 150, width: 150, opacity: ALLOW_SUBMISSION_TYPE["suggestion"] ? 1 : 0.5 }}
                            onClick={() => {
                                if (!ALLOW_SUBMISSION_TYPE["suggestion"]) {
                                    notify({ type: "error", message: "This reporting method will be available soon, please try another type of reporting." })
                                    return
                                }
                                setPageState("suggestion")
                            }}
                        >
                            <FaClipboard color="white" size={30} />
                            <p className='mt-10' style={{ color: "white" }}>Suggestion Feedback</p>
                        </div>

                        {/* First Aid */}
                        <div className='alignCenter center flex column textCenter' style={{ backgroundColor: "gray", height: 150, width: 150, opacity: ALLOW_SUBMISSION_TYPE["firstaid"] ? 1 : 0.5 }}
                            onClick={() => {
                                if (!ALLOW_SUBMISSION_TYPE["firstaid"]) {
                                    notify({ type: "error", message: "This reporting method will be available soon, please try another type of reporting." })
                                    return
                                }
                                setPageState("firstaid")
                            }}
                        >
                            <FaFirstAid color="white" size={30} />
                            <p className='mt-10' style={{ color: "white" }}>First Aid</p>
                        </div>

                    </div>

                    {(isAuthorised || !isAuthorised) &&
                        <div className='mt-10 center alignCenter gap-10 flex mb-40'>

                            {/* Inspections */}
                            <div className='alignCenter center flex column textCenter' style={{ backgroundColor: "gray", height: 150, width: 150, opacity: ALLOW_SUBMISSION_TYPE["inspection"] ? 1 : 0.5 }}
                                onClick={() => {
                                    if (!ALLOW_SUBMISSION_TYPE["inspection"]) {
                                        notify({ type: "error", message: "This reporting method will be available soon, please try another type of reporting." })
                                        return
                                    }
                                    setPageState("inspection")
                                }}
                            >
                                <FaTools color="white" size={30} />
                                <p className='mt-10' style={{ color: "white" }}>Inspections</p>
                            </div>
                        </div>}
                </div>}

                {(pageState === "incident" || pageState === "compliment" || pageState === "suggestion") && <>
                    {inputType === "camera" && <Camera
                        isFullscreen
                        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    />}
                    <p style={{ color: "black", marginBottom: 10, flexWrap: "wrap" }} className='center gap-10 alignCenter row'>Reporting <span style={{ color: "black", fontWeight: "bold" }}>{pageState === "incident" ? "incident/hazard" : pageState}</span> at <span style={{ color: "black", fontWeight: "bold" }}>{locationName?.location_name}</span></p>
                    <button className='backgroundLess mb-40 m-all row alignCenter gap-10' onClick={() => {
                        setPageState("selection")
                    }}>
                        <MdHome size={30} color="black" />
                        <p>Back</p>
                    </button>
                    <div className="p-all mt-40 column center alignCenter">

                        {/* <div className="column center alignCenter"
                                onClick={() => {
                                    // take photo
                                    setInputType("camera")
                                }}
                            >
                                <FaCamera color="black" size={30} />
                                <p style={{ fontSize: 12, color: "black", textAlign: "center" }}>Take a photo</p>
                            </div> */}
                        {/* <div className="column center alignCenter"
                                onClick={() => {
                                    // take photo
                                    document.getElementById('files').click()
                                }}
                            >
                                <FaCamera color="black" size={30} />
                                <p style={{ fontSize: 12, color: "black", textAlign: "center" }}>Attach a file</p>
                                <input type="file" onChange={(e) => {
                                    let files = e.target.files
                                    let newFiles = []
                                    for (let i = 0; i < files.length; i++) {
                                        newFiles.push(files[i])
                                    }
                                    setReportData({ ...reportData, files: newFiles })
                                }} style={{ display: "none" }} id='files' />
                            </div> */}

                        <div className="input-container">
                            <p className="input-label">Attach Files</p>
                            <div
                                style={{
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderStyle: "dashed",
                                    borderRadius: 10,
                                    padding: 10,
                                }}
                            >
                                <form action="" encType="multipart/form-data">
                                    <input type="file"
                                        onChange={(e) => {
                                            let files = e.target.files
                                            let newFiles = []
                                            for (let i = 0; i < files.length; i++) {
                                                newFiles.push(files[i])
                                            }
                                            console.log(newFiles)
                                            setReportData({ ...reportData, files: newFiles })
                                        }}
                                        style={{ color: "black", width: "100%" }}
                                        // value={reportData.files}
                                        id='files'
                                        name='files'
                                        accept='image/*,video/*,audio/*'
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="input-label">Description</p>
                            <textarea className="textarea-input" placeholder="Provide context to this report (e.g. Broken glass near the front desk)" onChange={(e) => setReportData({ ...reportData, description: e.target.value })} />
                        </div>
                        <div className="input-container ">
                            <p className="input-label">Your Name <span style={{ fontSize: 10 }}>(optional)</span></p>
                            <input className="text-input" placeholder="Your name" onChange={(e) => setReportData({ ...reportData, reporter_name: e.target.value })} />
                        </div>
                        <div className="input-container ">
                            <p className="input-label">Contact Information <span style={{ fontSize: 10 }}>(optional)</span></p>
                            <input className="text-input" placeholder="e.g. 9700 8000" onChange={(e) => setReportData({ ...reportData, contact_information: e.target.value })} />
                            <p style={{ fontSize: 12 }}>Please provide your contact details for follow up or promotional rewards, all information will be kept strict confidential</p>
                        </div>
                        {reportData.files?.filter((file) => file.type.includes("image")).length > 0 && < p style={{ color: "black" }}>{reportData.files?.filter((file) => file.type.includes("image")).length} images attached</p>}
                        {reportData.files?.filter((file) => file.type.includes("audio")).length > 0 && <p style={{ color: "black" }}>{reportData.files?.filter((file) => file.type.includes("audio")).length} audio files attached</p>}
                        {reportData.files?.filter((file) => file.type.includes("video")).length > 0 && <p style={{ color: "black" }}>{reportData.files?.filter((file) => file.type.includes("video")).length} videos attached</p>}

                        <div className="row gap-10 center alignCenter mt-40">
                            <button className="primary-button" style={{ backgroundColor: isSending ? "gray" : "#1E90FF" }}
                                disabled={isSending}
                                onClick={() => {
                                    if (!reportData?.contact_information) {
                                        IAPrompt({
                                            title: "Contact Information",
                                            message: "Please provide your contact details for follow up or promotional rewards, all information will be kept strict confidential. Do you want to proceed without providing your contact information?",
                                            buttons: [
                                                {
                                                    label: "No",
                                                    onClick: () => { }
                                                },
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        submitReport()
                                                    }
                                                },
                                            ]
                                        })
                                    } else {
                                        submitReport()
                                    }
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </>}
                {/* Inspections */}
                {(pageState === "inspection") &&
                    <>
                        {/* Top Nav Header */}
                        <p style={{ color: "black", marginBottom: 10 }} className='center gap-10 alignCenter row'><span style={{ color: "black", fontWeight: "bold" }}>{locationName?.location_name}</span></p>
                        <p style={{ color: "black", marginBottom: 10 }} className='center gap-10 alignCenter row'><span style={{ color: "black", fontWeight: "bold" }}>{pageState === "inspection" ? "Inspection" : "First Aid"}</span> at <span style={{ color: "black", fontWeight: "bold" }}>{locationName?.location_name}</span></p>
                        <button className='backgroundLess mb-40 m-all row alignCenter gap-10' onClick={() => {
                            setPageState("selection")
                        }}>
                            <MdHome size={30} color="black" />
                            <p>Back</p>
                        </button>
                        <div className="p-all mt-40 column center alignCenter">



                            <div className="card" style={{}}>
                                <p className="card-title">Form</p>
                                {/* <pre>{JSON.stringify(checklistUserData, null, 2)}</pre> */}
                                <h1 style={{ color: "black", fontSize: 20 }}>{checklistData?.checklist_name}</h1>
                                <p style={{ color: "gray" }}>{checklistData?.checklist_description}</p>
                            </div>
                            <div style={{ borderBottom: "1px solid black", width: "100%", height: "10px", paddingTop: 10, marginBottom: 10 }} />
                            {/* <pre>{JSON.stringify(checklistData, null, 2)}</pre> */}
                            {checklistData?.checklist_items?.map((item, index) => {
                                return (
                                    <div className="card" key={index} style={{ marginBottom: 10 }}>
                                        <p className="card-title">{index + 1}. {item?.item_name}</p>
                                        <p style={{ color: "gray" }}>{item?.item_description}</p>
                                        <div className="row gap-10">
                                            {item?.item_type === "text" && <textarea className="textarea-input" placeholder="Enter text here" onChange={(e) => {
                                                let newInspectionData = { ...checklistUserData }
                                                newInspectionData.inspection_response[index].item_value = e.target.value
                                                setChecklistUserData(newInspectionData)
                                            }} />}
                                            {item?.item_type === "checkbox" && <div>
                                                {item?.item_options?.map((option, index1) => {
                                                    return (
                                                        <div className="row gap-10 mt-40" key={index1} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                                            <input type="checkbox" onChange={(e) => {
                                                                let newInspectionData = { ...checklistUserData }
                                                                newInspectionData.inspection_response[index].selection[index1] = e.target.checked
                                                                setChecklistUserData(newInspectionData)
                                                            }} />
                                                            <p>{option}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            }
                                            {item?.item_type === "radio" && <div>
                                                {item?.item_options?.map((option, index1) => {
                                                    return (
                                                        <div className="row gap-10 center mt-40" key={index1} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                                            <input type="radio" name={item?.item_name} onChange={(e) => {
                                                                let newInspectionData = { ...checklistUserData }
                                                                // newInspectionData.inspection_response[index].selection = newInspectionData.inspection_response[index].selection?.map((item) => false)
                                                                newInspectionData.inspection_response[index].selection = option
                                                                setChecklistUserData(newInspectionData)
                                                            }} />
                                                            <p>{option}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            }

                                            {item?.item_type === "file" &&
                                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                    <div className="input-container mt-40" style={{ cursor: "grab" }} onClick={() => {
                                                        // document.getElementById(`files${index}`).click()
                                                    }}>
                                                        {/* <FaFile color="black" size={30} /> */}
                                                        <p className="input-label">Attach Files</p>
                                                        {/* <div
                                                            onClick={() => {
                                                                // take photo
                                                                document.getElementById('files').click()
                                                            }}

                                                            style={{
                                                                cursor: "grab",
                                                                borderColor: "gray",
                                                                borderWidth: 1,
                                                                borderStyle: "dashed",
                                                                borderRadius: 10,
                                                                padding: 10,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            <input type="file" onChange={(e) => {
                                                                let files = e.target.files
                                                                let newFiles = []
                                                                for (let i = 0; i < files.length; i++) {
                                                                    newFiles.push(files[i])
                                                                }
                                                                setReportData({ ...reportData, files: newFiles })
                                                            }} style={{ color: "black" }} id={`files${index}`} />
                                                        </div> */}
                                                        <form action="" encType="multipart/form-data">
                                                            <input type="file"
                                                                onChange={(e) => {
                                                                    let files = e.target.files
                                                                    let newFiles = []
                                                                    for (let i = 0; i < files.length; i++) {
                                                                        newFiles.push(files[i])
                                                                    }
                                                                    console.log(newFiles)
                                                                    setReportData({ ...reportData, files: newFiles })
                                                                }}
                                                                style={{ color: "black", width: "100%" }}
                                                                // value={reportData.files}
                                                                id={`files${index}`}
                                                                name='files'
                                                                accept='image/*,video/*,audio/*'
                                                            />
                                                        </form>

                                                    </div>
                                                    <button className="primary-button mt-40"
                                                        onClick={() => {
                                                            uploadFileInspection(`files${index}`, index)
                                                        }}
                                                    >
                                                        Attach
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                        {item?.allow_remarks && <div className="input-container mt-20">
                                            <p className="input-label">Remarks</p>
                                            <textarea className="textarea-input" placeholder="Enter remarks here" onChange={(e) => {
                                                let newInspectionData = { ...checklistUserData }
                                                newInspectionData.inspection_response[index].remarks = e.target.value
                                                setChecklistUserData(newInspectionData)
                                            }} />
                                        </div>}
                                    </div>
                                )
                            })}

                            <div className="input-container">
                                <p className="input-label">Inspection Remarks</p>
                                <textarea className="textarea-input" placeholder="Enter remarks here" value={checklistUserData?.inspection_remarks} onChange={(e) => setChecklistUserData({ ...checklistUserData, inspection_remarks: e.target.value })} />
                            </div>
                            <div className="row gap-10 center alignCenter mt-40">
                                <button className="primary-button" style={{ backgroundColor: isSending ? "gray" : "#1E90FF" }}
                                    disabled={isSending}
                                    onClick={(e) => {
                                        submitInspection()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </>
                }

                {/* First Aid */}
                {(pageState === "firstaid") &&
                    <>
                        {/* Top Nav Header */}
                        <p style={{ color: "black", marginBottom: 10 }} className='center gap-10 alignCenter row'><span style={{ color: "black", fontWeight: "bold" }}>{locationName?.location_name}</span></p>
                        <p style={{ color: "black", marginBottom: 10 }} className='center gap-10 alignCenter row'><span style={{ color: "black", fontWeight: "bold" }}>{pageState === "inspection" ? "Inspection" : "First Aid"}</span> at <span style={{ color: "black", fontWeight: "bold" }}>{locationName?.location_name}</span></p>
                        <button className='backgroundLess mb-40 m-all row alignCenter gap-10' onClick={() => {
                            setPageState("selection")
                        }}>
                            <MdHome size={30} color="black" />
                            <p>Back</p>
                        </button>
                        <div className="p-all mt-40 column center alignCenter">



                            <div className="card" style={{}}>
                                <p className="card-title">Form</p>
                                {/* <pre>{JSON.stringify(checklistUserData, null, 2)}</pre> */}
                                <h1 style={{ color: "black", fontSize: 20 }}>{checklistData?.checklist_name}</h1>
                                <p style={{ color: "gray" }}>{checklistData?.checklist_description}</p>
                            </div>
                            <div style={{ borderBottom: "1px solid black", width: "100%", height: "10px", paddingTop: 10, marginBottom: 10 }} />
                            {/* <pre>{JSON.stringify(checklistData, null, 2)}</pre> */}
                            {checklistData?.checklist_items?.map((item, index) => {
                                return (
                                    <div className="card" key={index} style={{ marginBottom: 10 }}>
                                        <p className="card-title">{index + 1}. {item?.item_name}</p>
                                        <p style={{ color: "gray" }}>{item?.item_description}</p>
                                        <div className="row gap-10">
                                            {item?.item_type === "text" && <textarea className="textarea-input" placeholder="Enter text here" onChange={(e) => {
                                                let newChecklistData = { ...checklistUserData }
                                                newChecklistData.response[index].item_value = e.target.value
                                                setChecklistUserData(newChecklistData)
                                            }} />}
                                            {item?.item_type === "checkbox" && <div>
                                                {item?.item_options?.map((option, index1) => {
                                                    return (
                                                        <div className="row gap-10  mt-40" key={index1} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                                            <input type="checkbox" onChange={(e) => {
                                                                let newChecklistData = { ...checklistUserData }
                                                                newChecklistData.response[index].selection[index1] = e.target.checked
                                                                setChecklistUserData(newChecklistData)
                                                            }} />
                                                            <p>{option}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            }
                                            {item?.item_type === "radio" && <div>
                                                {item?.item_options?.map((option, index1) => {
                                                    return (
                                                        <div className="row gap-10 center  mt-40" key={index1} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                                            <input type="radio" name={item?.item_name} onChange={(e) => {
                                                                let newChecklistData = { ...checklistUserData }
                                                                // newChecklistData.response[index].selection = newChecklistData.response[index].selection?.map((item) => false)
                                                                newChecklistData.response[index].selection = option
                                                                setChecklistUserData(newChecklistData)
                                                            }} />
                                                            <p>{option}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            }

                                            {item?.item_type === "file" &&
                                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                    <div className="input-container mt-40" style={{ cursor: "grab" }} onClick={() => {
                                                        // document.getElementById(`files${index}`).click()
                                                    }}>
                                                        {/* <FaFile color="black" size={30} /> */}
                                                        <p className="input-label">Attach Files</p>
                                                        {/* <div
                                                            onClick={() => {
                                                                // take photo
                                                                document.getElementById('files').click()
                                                            }}

                                                            style={{
                                                                cursor: "grab",
                                                                borderColor: "gray",
                                                                borderWidth: 1,
                                                                borderStyle: "dashed",
                                                                borderRadius: 10,
                                                                padding: 10,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            <input type="file" onChange={(e) => {
                                                                let files = e.target.files
                                                                let newFiles = []
                                                                for (let i = 0; i < files.length; i++) {
                                                                    newFiles.push(files[i])
                                                                }
                                                                setReportData({ ...reportData, files: newFiles })
                                                            }} style={{ color: "black" }} id={`files${index}`} />
                                                        </div> */}
                                                        <form action="" encType="multipart/form-data">
                                                            <input type="file"
                                                                onChange={(e) => {
                                                                    let files = e.target.files
                                                                    let newFiles = []
                                                                    for (let i = 0; i < files.length; i++) {
                                                                        newFiles.push(files[i])
                                                                    }
                                                                    console.log(newFiles)
                                                                    setReportData({ ...reportData, files: newFiles })
                                                                }}
                                                                style={{ color: "black", width: "100%" }}
                                                                // value={reportData.files}
                                                                id={`files${index}`}
                                                                name='files'
                                                                accept='image/*,video/*,audio/*'
                                                            />
                                                        </form>

                                                    </div>
                                                    <button className="primary-button mt-40"
                                                        onClick={() => {
                                                            uploadFileFirstAid(`files${index}`, index)
                                                        }}
                                                    >
                                                        Attach
                                                    </button>
                                                </div>
                                            }

                                        </div>
                                        {item?.allow_remarks && <div className="input-container mt-20">
                                            <p className="input-label">Remarks</p>
                                            <textarea className="textarea-input" placeholder="Enter remarks here" onChange={(e) => {
                                                let newChecklistData = { ...checklistUserData }
                                                newChecklistData.response[index].remarks = e.target.value
                                                setChecklistUserData(newChecklistData)
                                            }} />
                                        </div>}
                                    </div>
                                )
                            })}

                            <div className="input-container">
                                <p className="input-label">First Aid Remarks</p>
                                <textarea className="textarea-input" placeholder="Enter remarks here" value={checklistUserData?.remarks} onChange={(e) => setChecklistUserData({ ...checklistUserData, remarks: e.target.value })} />
                            </div>
                            <div className="row gap-10 center alignCenter mt-40">
                                <button className="primary-button" style={{ backgroundColor: isSending ? "gray" : "#1E90FF" }}
                                    disabled={isSending}
                                    onClick={(e) => {
                                        submitFirstAid()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div >}
            {pageState === "success" &&
                <>
                    <div className="center alignCenter column" style={{ marginTop: 40 }}>
                        <div style={{ padding: 30, borderRadius: "50%", backgroundColor: "greenyellow" }}>
                            <FaThumbsUp color="black" size={50} style={{}} />
                        </div>
                        <p style={{ fontSize: 20, color: "black", textAlign: "center", marginTop: 20 }}>Thank you for your report!</p>
                        <p style={{ fontSize: 12, color: "black", textAlign: "center" }}>Your report has been submitted successfully</p>
                        <button className="primary-button mt-40"
                            onClick={() => {
                                setPageState("selection")
                            }}
                        >Back</button>
                    </div>
                </>
            }
        </>
    )
}
