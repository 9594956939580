import React, { useState, useEffect, useLayoutEffect } from 'react'
import UserHeader from '../../components/UserHeader'
import { useFetchUser } from '../../utils/AppFunctions'
import config from '../../app-config'
import { tidyDate } from '../../utils/AppHelpers'
import { MdConnectingAirports, MdOutlinePersonPinCircle, MdPerson, MdPersonPinCircle, MdSettings } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export default function UserHomePage() {
    const user = useFetchUser()
    const navigate = useNavigate()

    const [userReportData, setUserReportData] = useState()
    const [userPoints, setUserPoints] = useState(0)
    const [selectedView, setSelectedView] = useState("leaderboard") // leaderboard, report

    const [leaderboardData, setLeaderboardData] = useState()
    const [announcement, setAnnouncement] = useState("")


    const user_data = useFetchUser()

    const fetchUserReportData = async () => {
        if (!user?.id) return
        let endpoint = config.endpoint + "/report/user/id/" + user?.id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setUserReportData(data.data?.reverse())
        }
    }

    const fetchLeaderboardData = async () => {
        let endpoint = config.endpoint + "/game/user/leaderboard"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setLeaderboardData(data.data)
        }
    }

    const fetchAnnouncement = async () => {
        let endpoint = config.endpoint + "/config/announcement"
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setAnnouncement(data.data)
        }
    }

    const fetchUserPoints = async () => {
        if (!user?.id) return
        let endpoint = config.endpoint + "/game/user/id/" + user?.id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setUserPoints(data.data)
        }
    }

    const datetime = () => {
        let dateObj = new Date()
        let hours = dateObj.getHours()
        let minutes = dateObj.getMinutes()
        let seconds = dateObj.getSeconds()
        let day = dateObj.getDay()
        let date = dateObj.getDate()
        let month = dateObj.getMonth()
        let year = dateObj.getFullYear()
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
    }

    useEffect(() => {
        fetchUserReportData()
        fetchUserPoints()
        fetchAnnouncement()
        fetchLeaderboardData()
    }, [user])


    // useLayoutEffect(() => {
    //     if (!user_data) window.location.href = "/user/login"
    // }, [user_data])

    return (
        <div style={{ height: "100dvh" }}>
            <UserHeader />
            {announcement && <div className="" style={{ height: "auto", marginBottom: 10 }}>
                <div className="">
                    <div className="component" style={{ padding: 20 }}>
                        <div className="column alignTop" style={{ width: "100%" }}>
                            <p style={{ fontSize: 12, margin: 0, padding: 0, textAlign: "center" }}>{announcement}</p>
                        </div>
                    </div>
                </div>
            </div>}


            <div className='container' style={{ height: "auto" }}>
                <div className="inner-container">
                    <div className="component" style={{ borderRadius: 10, padding: 20 }}>
                        <div className="column alignTop" style={{ width: "100%" }}>
                            <div className='cursor-pointer justifyRight' style={{ display: "flex", flexDirection:"row", justifyContent:"right", width:"100%"}}
                                onClick={()=>{
                                    navigate("/user/profile")
                                }}
                            >
                                <MdPerson size={30} color='black' />
                            </div>
                            <p style={{ marginTop: 10 }}>It's {datetime().day}, {datetime().date}</p>
                            <p className="b">Welcome Back, @{user?.username}!</p>
                            <p style={{ marginTop: 10, fontSize: 12 }}><span style={{ fontSize: 35 }}>{userPoints}</span> points</p>
                            <progress value={userReportData?.length === 0 ? 0 : userReportData?.filter(item => item?.report_status === "resolved").length} max={userReportData?.length === 0 ? 1 : userReportData?.filter(item => (item?.report_status === "resolving" || item?.report_status === "open" || item?.report_status === "resolved")).length} style={{ width: "100%", }} />
                            <div className="row" style={{ marginTop: 10, alignItems: "center" }}>
                                <div style={{ height: 10, width: 10, borderRadius: 5, backgroundColor: "#1E90FF" }} />
                                <p style={{ fontSize: 10, marginLeft: 5 }}>{userReportData?.filter(item => item?.report_status === "resolved").length} Resolved Reports</p>
                                <div style={{ height: 10, marginLeft: 20, width: 10, borderRadius: 5, backgroundColor: "#ccc" }} />
                                <p style={{ fontSize: 10, marginLeft: 5 }}>{userReportData?.filter(item => item?.report_status === "resolving" || item?.report_status === "open").length} Open Reports</p>
                            </div>
                        </div>
                    </div>
                    <div className="component column alignTop mt-10" style={{ width: "100%", borderRadius: 10 }}>
                        <div className="row gap-10 p-all alignBottom">
                            <button
                                className="backgroundLess"
                                style={{ cursor: "pointer", color: "black", fontWeight: selectedView === "leaderboard" ? "bold" : "400", fontSize: selectedView === "leaderboard" ? 15 : 12, textDecoration: selectedView === "leaderboard" ? "underline" : "none" }}
                                onClick={() => {
                                    setSelectedView("leaderboard")
                                }}
                            >Leaderboard</button>
                            <button
                                className="backgroundLess"
                                style={{ cursor: "pointer", color: "black", fontWeight: selectedView === "reports" ? "bold" : "400", fontSize: selectedView === "reports" ? 15 : 12, textDecoration: selectedView === "reports" ? "underline" : "none" }}
                                onClick={() => {
                                    setSelectedView("reports")
                                }}
                            >My Reports</button>
                        </div>

                        {selectedView === "leaderboard" && <div style={{ width: "100%" }}>
                            <p style={{ fontSize: 13, margin: 0, padding: 0, textAlign: "center" }}>{tidyDate(leaderboardData?.game_start)} to {tidyDate(leaderboardData?.game_end)}</p>
                            {leaderboardData?.show_leaderboard === 1 ? leaderboardData?.positions?.map((item, index) => {
                                return (
                                    <div className="component" style={{ padding: 10, marginTop: 10 }} key={index}>
                                        <p style={{ marginRight: 10 }}>{index + 1}.</p>
                                        <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
                                            <div>
                                                <p className="b" style={{ fontSize: 12 }}>{item?.username || item?.email || item?.telegram}</p>
                                            </div>
                                            <p style={{ color: "gray" }}>{item?.points} pts</p>
                                        </div>
                                    </div>
                                )
                            }) :
                                <div style={{ padding: 10, marginTop: 10 }}>
                                    <p style={{ fontSize: 15 }}>please wait for the leaderboard to be released</p>
                                </div>
                            }
                        </div>}

                        {selectedView === "reports" && <div style={{ width: "100%" }}>
                            {userReportData?.length > 0 ? userReportData?.map((item, index) => {
                                return (
                                    <div className="component" style={{ padding: 10, marginTop: 10 }} key={index}>
                                        <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
                                            <div>
                                                <p className="b" style={{ fontSize: 12 }}>case #{item?.id}</p>
                                                <p className="400" style={{ fontSize: 12 }}>{tidyDate(item?.reported_at)}</p>
                                            </div>
                                            <p style={{ color: "gray" }}>+{item?.points}</p>
                                        </div>
                                    </div>
                                )
                            }) :
                                <div style={{ padding: 10, marginTop: 10 }}>
                                    <p style={{ fontSize: 12 }}>You have not submitted any reports yet, to submit a report, please scan the QR Code at your current location. </p>
                                    <ul style={{ color: "black", paddingLeft: 20, fontSize: 12 }}>
                                        <li>Use any QR Code Scanner to scan the QR Code.</li>
                                        <li>You may choose to use Telegram or the Web Application to make your report.</li>
                                        <li>Remember to Log In to participate and a chance to win prizes!</li>
                                    </ul>
                                </div>
                            }
                        </div>}
                    </div>
                    <div className="mt-20">
                        <p style={{ textAlign: "center", fontSize: 12 }}>SIT Safety Reporting Platform © 2023</p>
                        <p style={{ textAlign: "center", fontSize: 12 }}>Designed by students of 2021, Built by OurCodeLab LLP</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
